<template>
  <div>
    <div class="row registration-form__top-bar">
      <div class="col d-flex align-items-center">
        <img src="./registration-form/assets/disciple_logo.svg" height="36" class="ml-3" />
      </div>
    </div>

    <div class="container registration-form__viewport-container">
      <div class="registration-form__container mx-auto d-flex flex-column align-items-center">
        <p class="registration-form__main-text m-0">Start your web community in 3 easy steps</p>

        <img src="./registration-form/assets/steps.svg" class="mt-5 registration-form__steps-img" />

        <rails-form action="/signup" class="mt-5">
          <b-form-group label="First name*" label-for="first-name">
            <b-form-input
              id="first-name"
              v-model.trim="$v.user.first_name.$model"
              :state="$v.user.first_name | dirtyValidation"
              type="text"
              name="user[first_name]"
              placeholder="E.g. John"
            />
            <b-form-invalid-feedback>Please provide your first name</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Work email*" label-for="email">
            <b-form-input
              id="email"
              v-model.trim="$v.user.email.$model"
              :state="$v.user.email | dirtyValidation"
              type="email"
              name="user[email]"
              placeholder="E.g. john.apple@disciplemedia.com"
              autocomplete="email"
            />
            <b-form-invalid-feedback>Please provide a valid email address</b-form-invalid-feedback>
            <b-form-text v-if="errors.email" class="registration-form__error-text"
              >This email {{ errors.email.join(", ") }}</b-form-text
            >
          </b-form-group>

          <b-form-group label="Password*" label-for="password">
            <b-form-input
              id="password"
              v-model="$v.user.password.$model"
              :state="$v.user.password | dirtyValidation"
              type="password"
              name="user[password]"
              autocomplete="new-password"
            />
            <b-form-invalid-feedback>Please provide a password that is more than 10 characters</b-form-invalid-feedback>
            <b-form-text>Must be at least 10 characters.</b-form-text>
          </b-form-group>

          <b-form-group v-if="hcaptchaSitekey">
            <vue-hcaptcha id="captcha-challenge" :sitekey="hcaptchaSitekey" @verify="isHcaptchaVerified = true" />
            <b-form-text v-if="errors.captcha" class="registration-form__error-text">{{
              errors.captcha.join(", ")
            }}</b-form-text>
          </b-form-group>

          <input
            v-if="Boolean(marketingCampaign)"
            id="marketing-campaign"
            name="user[marketing_campaign]"
            type="hidden"
            :value="marketingCampaign"
          />

          <div class="form-check mb-2">
            <input
              id="tosAndPp"
              v-model="$v.user.tos_and_pp.$model"
              type="checkbox"
              name="user[tos_and_pp]"
              class="form-check-input"
              :class="{ 'is-invalid': $v.user.tos_and_pp.$error }"
              value="1"
            />
            <label class="form-check-label registration-form__checkbox-label" for="tosAndPp">
              By creating an account, I agree to the
              <a
                href="https://www.disciplemedia.com/product-terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                >Terms and Conditions</a
              >
              and
              <a href="https://www.disciplemedia.com/privacy-policy/" target="_blank" rel="noopener noreferrer"
                >Privacy Policy</a
              >
              of Disciple Media.*
            </label>
            <b-form-invalid-feedback>Please tick the box above to continue.</b-form-invalid-feedback>
          </div>

          <div class="form-check">
            <input
              id="marketingOptIn"
              v-model="user.marketing_opt_in"
              type="checkbox"
              name="user[marketing_opt_in]"
              class="form-check-input"
              value="1"
            />
            <label class="form-check-label registration-form__checkbox-label" for="marketingOptIn">
              I want to receive top tips on community management.
            </label>
          </div>

          <button
            type="submit"
            class="btn btn-primary w-100 mt-4 registration-form__submit-button"
            :class="{ disabled: !isSubmitEnabled }"
            @click="onSubmit"
          >
            Start my free trial
          </button>
        </rails-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import RailsForm from "./rails-form";

export default {
  components: {
    RailsForm,
    VueHcaptcha,
  },
  mixins: [validationMixin],
  data() {
    return {
      user: {
        first_name: "",
        email: "",
        password: "",
        tos_and_pp: false,
        marketing_agreed: false,
      },
      errors: {
        first_name: null,
        email: null,
        password: null,
        tos_and_pp: null,
        marketing_agreed: null,
        captcha: null,
      },
      isHcaptchaVerified: false,
    };
  },
  computed: {
    marketingCampaign() {
      const params = new URLSearchParams(location.search);
      return params.get("campaign");
    },
    isSubmitEnabled() {
      return (!this.hcaptchaSitekey || this.isHcaptchaVerified) && !this.$v.user.$invalid;
    },
  },
  beforeMount() {
    const formUser = JSON.parse(this.$el.dataset.user);
    formUser.marketing_opt_in = formUser.marketing_opt_in === "1";
    formUser.tos_and_pp = formUser.tos_and_pp === "1";
    Object.assign(this.user, formUser);

    const formErrors = JSON.parse(this.$el.dataset.errors);
    Object.assign(this.errors, formErrors);

    this.hcaptchaSitekey = this.$el.dataset.hcaptchaSitekey;
  },
  methods: {
    onSubmit(e) {
      this.$v.user.$touch();

      if (!this.isSubmitEnabled) {
        e.preventDefault();
      }
    },
  },
  validations: {
    user: {
      first_name: {
        required,
        maxLength: maxLength(30),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(10),
      },
      tos_and_pp: {
        sameAs: sameAs(() => true),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-form {
  &__top-bar {
    height: 60px;
    width: 100%;

    box-shadow:
      0px 2px 2px rgba(0, 0, 0, 0.1),
      0px 0.5px 5px rgba(0, 0, 0, 0.15);

    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    background: #f5f5f5;
  }

  &__viewport-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-top: 3rem;
    margin-bottom: 32px;

    @media (min-height: 800px) {
      height: calc(100vh - 5rem - 5rem - 60px);
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  &__container {
    max-width: 384px;
  }

  &__steps-img {
    width: 100%;

    padding: 0 48px;

    @media (min-width: 768px) {
      padding: 0 64px;
    }
  }

  &__main-text {
    font-weight: bold;
    font-size: 24px;
    line-height: 115%;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 130%;
    }
  }

  &__checkbox-label {
    font-size: 0.75rem;
    line-height: 130%;
  }

  &__submit-button {
    height: 42px;

    font-weight: bold;
    font-size: 16px;
  }

  &__error-text {
    color: red !important;
  }
}

.btn {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));

  &:disabled {
    filter: none;
  }
}
</style>
