<template>
  <b-popover :target="target" triggers="click" position="top" :show.sync="isShown" @hidden="onHide" v-on="$listeners">
    <template v-slot:title> Pick a colour </template>

    <chrome-picker v-model="color" :disable-alpha="true" />

    <div class="d-flex justify-content-center mt-2">
      <button class="btn btn-primary w-100 mr-2" @click="isShown = false">OK</button>
      <button class="btn btn-secondary w-100" @click="onCancel">Cancel</button>
    </div>
  </b-popover>
</template>

<script>
import { Chrome as ChromePicker } from "vue-color";
import _ from "lodash";

export default {
  components: {
    ChromePicker,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShown: false,
      originalValue: this.value,
    };
  },
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.emitValue(newVal.hex, this);
      },
    },
  },
  methods: {
    emitValue: _.debounce((value, vm) => vm.$emit("input", value), 200),
    onHide() {
      this.originalValue = this.value;
    },
    onCancel() {
      this.$emit("input", this.originalValue);
      this.isShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.vc-chrome {
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;

  & ::v-deep .vc-chrome-saturation-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px 4px 0 0;
  }
}
</style>
