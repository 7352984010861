<template>
  <div class="cpmy__container">
    <p class="cpmy__heading" :style="{ color: masterColors.wall_text }">My groups</p>

    <div class="cpmy__groups">
      <div class="cpmy__group">
        <img src="./assets/group_1.png" />
        <span :style="{ color: masterColors.wall_text }">Introduce yourself</span>
      </div>

      <div class="cpmy__group">
        <img src="./assets/group_2.png" />
        <span :style="{ color: masterColors.wall_text }">The main hangout</span>
      </div>

      <div class="cpmy__group">
        <img src="./assets/group_3.png" />
        <span :style="{ color: masterColors.wall_text }">The music room</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpmy {
  &__container {
    padding-left: 12px;

    margin-top: 11px;
  }

  &__heading {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
  }

  &__groups {
    display: flex;
    flex-wrap: nowrap;
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      min-width: 83px;
      max-width: 83px;
      height: 60px;
    }

    span {
      font-size: 11px;
      text-align: center;
      white-space: nowrap;
      margin-top: 2px;
    }

    & + & {
      margin-left: 8px;
    }
  }
}
</style>
