<template>
  <b-form-group label="New domain" label-for="hostname">
    <spinner-overlay v-if="isLoading" />

    <div class="form-row">
      <div class="col col-md-5">
        <b-form-input id="hostname" v-model="hostnameModel" type="text" :state="$v.hostname | dirtyValidation" />
        <b-form-invalid-feedback v-if="!$v.hostname.required"> This input is required. </b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.hostname.validHostname"> This domain is not valid. </b-form-invalid-feedback>
        <b-form-feedback v-if="!$v.hostname.characters">
          The domain must consist of lower case characters, numbers, dashes or dots.
        </b-form-feedback>
        <b-form-invalid-feedback v-if="!$v.hostname.serverError">
          <div v-for="(message, idx) in serverErrors.hostname" :key="idx">This domain {{ message }}.</div>
        </b-form-invalid-feedback>
      </div>
      <div class="col-auto">
        <button class="btn btn-secondary" @click="onSubmit">Submit</button>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import validUrl from "valid-url";

import SpinnerOverlay from "../spinner-overlay";

export default {
  components: {
    SpinnerOverlay,
  },
  mixins: [validationMixin],
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hostname: "",

      isLoading: false,
      serverErrors: {
        hostname: null,
      },
    };
  },
  computed: {
    hostnameModel: {
      get() {
        return this.$v.hostname.$model;
      },
      set(newVal) {
        this.$v.hostname.$model = newVal;
        this.serverErrors.hostname = null;
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;
      (async () => {
        try {
          const hostnameStripped = this.hostname.slice(-1) === "." ? this.hostname.slice(0, -1) : this.hostname;
          const response = await $.post(`/api/clients/${this.clientId}/custom_domain`, {
            custom_domain: { hostname: hostnameStripped },
          });

          if (response.errors) {
            Object.assign(this.serverErrors, response.errors);
          } else {
            this.$emit("input", response.custom_domain);
          }

          this.isLoading = false;
        } catch {
          window.location.reload();
        }
      })();
    },
    resetServerErrors() {
      Object.keys(this.serverErrors).forEach((key) => (this.serverErrors[key] = null));
    },
  },
  validations: {
    hostname: {
      required,
      validHostname: (value) => {
        return !helpers.req(value) || Boolean(validUrl.isHttpUri(`http://${value}/validate_disciple_hostname`));
      },
      characters: helpers.regex("hostname", /^[a-z0-9-.]*$/),
      serverError: (_, vm) => !vm.serverErrors.hostname,
    },
  },
};
</script>
