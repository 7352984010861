<template>
  <div v-if="backendStatus" class="d-flex flex-column align-items-center w-100">
    <div class="progress bpi__progress">
      <div class="progress-bar bpi__progress-bar" :style="{ width: `${progress}%` }" />
    </div>
    <div class="bpi__text mt-1">{{ text }}</div>
  </div>
</template>

<script>
const DURATION = 180; // Seconds

export default {
  props: {
    backendStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      timerProgress: 0,
    };
  },
  computed: {
    progress() {
      if (this.backendStatus === "ready") {
        return 100;
      }

      return this.timerProgress;
    },
    text() {
      if (this.progress >= 100) {
        return "Your community is ready!";
      } else if (this.progress >= 80) {
        return "Polishing it all off";
      } else if (this.progress >= 60) {
        return "Building your member database";
      } else if (this.progress >= 40) {
        return "Building your community console";
      } else if (this.progress >= 20) {
        return "Adding your logo and theme";
      } else {
        return "Creating your community space";
      }
    },
  },
  mounted() {
    const intervalId = setInterval(() => {
      this.timerProgress += 0.1;
      if (this.timerProgress >= 83.3) {
        clearInterval(intervalId);
      }
    }, DURATION);
  },
};
</script>

<style lang="scss" scoped>
.bpi {
  &__progress {
    height: 12px;

    background-color: rgba(255, 255, 255, 0.3);

    max-width: 400px;
    width: 80%;

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  &__progress-bar {
    background-color: #0f545c;
  }

  &__text {
    font-weight: bold;
    color: #0f545c;

    font-size: 13px;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
}
</style>
