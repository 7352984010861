<template>
  <div v-if="client" class="row card-row">
    <div class="col">
      <div class="disciple-card domain-card">
        <spinner-overlay v-if="isLoading" />

        <div class="d-flex">
          <h4 class="disciple-card__header">Domain</h4>
          <a
            href="https://help.disciplemedia.com/hc/en-gb/articles/6399472688273-How-to-Create-a-Custom-Domain"
            target="_blank"
            rel="noopener noreferrer"
            class="d-flex align-items-center ml-auto font-weight-bold text-primary text-decoration-none"
          >
            Docs
            <i class="icon-link ml-2" />
          </a>
        </div>
        <p>Customise the domain for your community.</p>

        <p>
          Current domain:
          <span class="font-weight-bold text-nowrap">{{ client.hostname }}</span>
        </p>

        <template v-if="client.custom_domain">
          <div class="row">
            <div class="col-12 mb-3">
              <div class="mb-2 font-weight-bold">Domain</div>
              <div>{{ client.custom_domain.hostname }}</div>
            </div>
            <div class="col-12 mb-3">
              <div class="mb-2 font-weight-bold">Status</div>
              <div>
                <svg
                  v-if="
                    ['new', 'checking_dns_failed', 'applying_failed', 'removing_failed'].includes(
                      client.custom_domain.state,
                    )
                  "
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="m4.51844 4.51844c.69125-.69125 1.81198-.69125 2.50323 0l4.97833 4.97832 4.9783-4.97832c.6913-.69125 1.812-.69125 2.5033 0 .6912.69125.6912 1.81198 0 2.50323l-4.9784 4.97833 4.9784 4.9783c.6912.6913.6912 1.812 0 2.5033-.6913.6912-1.812.6912-2.5033 0l-4.9783-4.9784-4.97833 4.9784c-.69125.6912-1.81198.6912-2.50323 0-.69125-.6913-.69125-1.812 0-2.5033l4.97832-4.9783-4.97832-4.97833c-.69125-.69125-.69125-1.81198 0-2.50323z"
                    fill="#ff434a"
                    fill-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else-if="['applied', 'removed'].includes(client.custom_domain.state)"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="m21.3829 4.54598c.7856.75763.8266 2.0212.0916 2.82225l-11.0028 12.00717c-.69131.7533-1.83312.8358-2.62796.1898l-5.10098-4.8106c-.8452-.6869-.99069-1.9429-.32497-2.8053.66571-.8625 1.89055-1.0048 2.73575-.3179l3.80734 3.5642 9.66852-10.571c.735-.80106 1.9678-.83626 2.7535-.07862z"
                    fill="#34c9a7"
                    fill-rule="evenodd"
                  />
                </svg>
                <svg v-else fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#ffa869">
                    <path
                      d="m1.35696 10.1792 1.49104-.74551c-.88889 2.06451-1.08961 4.35841-.5448 6.56631.6595 2.6953 2.32258 4.9892 4.7025 6.4516 1.66309 1.0036 3.5269 1.5484 5.4194 1.5484.8315 0 1.6344-.086 2.4659-.2867 5.5627-1.3764 9.0036-7.0251 7.6559-12.5879-.6595-2.69529-2.3226-4.9892-4.7025-6.45157-1.3763-.86021-2.9247-1.34767-4.5018-1.49103v-1.29033h2.4086c.5162 0 .9463-.4301.9463-.946233 0-.516129-.4015-.946237-.9176-.946237h-6.681c-.51613 0-.94624.430108-.94624.946237 0 .516133.43011.946233.94624.946233h2.4086v1.29033c-.4875.05734-1.0036.11469-1.491.25806-.51617.1147-.83158.63082-.68821 1.14695.11469.51613.63082.83154 1.14691.68818 4.5592-1.08961 9.1757 1.72043 10.2939 6.27961 1.0897 4.5591-1.7204 9.1756-6.2795 10.2939-2.2653.5448-4.55916.172-6.50898-1.0036s-3.29749-3.0681-3.8423-5.276c-.45878-1.8638-.25806-3.7563.51613-5.4767l.71685 1.4624c.17204.344.48745.5161.86021.5161.14337 0 .28674-.0287.43011-.086.45878-.2294.6595-.8029.43011-1.2617l-1.74911-3.52687c-.11469-.22939-.31541-.40143-.5448-.48745s-.48745-.05735-.71685.05734l-3.555551 1.72043c-.4587813.22939-.659498.80287-.4301074 1.26165.2293904.4875.8028674.6595 1.2616484.4301z"
                    />
                    <path
                      d="m18.9053 13.6201c0-3.6129-2.9247-6.53768-6.5376-6.53768v6.53768l-4.61647 4.6164c1.17563 1.1757 2.81007 1.9212 4.61647 1.9212 3.6129 0 6.5376-2.9247 6.5376-6.5376z"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="mb-2 font-weight-bold">Info</div>
              <div>
                <template v-if="client.custom_domain.state === 'new'">
                  Please update your DNS so that
                  <span class="font-weight-bold">{{ client.custom_domain.hostname }}</span>
                  is a CNAME pointing to
                  <span class="font-weight-bold">{{ client.custom_ingress_hostname }}</span
                  >. Instructions
                  <a
                    href="https://help.disciplemedia.com/hc/en-gb/articles/6399472688273-How-to-Create-a-Custom-Domain"
                    target="_blank"
                    rel="noopener noreferrer"
                    >here</a
                  >.
                </template>
                <template
                  v-else-if="['checking_dns', 'applying_cmt', 'applying_infra'].includes(client.custom_domain.state)"
                >
                  Your domain is currently applying, this can take 24-48 hours.
                </template>
                <div v-else-if="client.custom_domain.state === 'applied'">
                  You're all setup with your custom domain.
                </div>
                <div v-else-if="['removing_cmt', 'removing_infra'].includes(client.custom_domain.state)">
                  Your domain is being removed, this can take 24-48 hours.
                </div>
                <div v-else-if="client.custom_domain.state === 'removed'">Your domain has been removed.</div>
                <div v-else-if="client.custom_domain.state === 'checking_dns_failed'" class="text-danger">
                  Oops. It doesn’t look like the CNAME has been configured correctly. Please check that everything has
                  been configured correctly. Please be aware that DNS changes can often take up to 24-48hrs to apply.
                  Instructions
                  <a
                    href="https://help.disciplemedia.com/hc/en-gb/articles/6399472688273-How-to-Create-a-Custom-Domain"
                    target="_blank"
                    rel="noopener noreferrer"
                    >here</a
                  >.
                </div>
                <div v-else-if="client.custom_domain.state === 'applying_failed'" class="text-danger">
                  Oops. It looks like something went wrong while setting up your domain. Our team has been notified and
                  will look into it soon. Please try again later.
                </div>
                <div v-else-if="client.custom_domain.state === 'removing_failed'" class="text-danger">
                  Oops. It looks like something went wrong while removing up your domain. Our team has been notified and
                  will look into it soon. Please try again later.
                </div>
              </div>
            </div>
            <div class="col-12">
              <template v-if="['new', 'checking_dns_failed'].includes(client.custom_domain.state)">
                <button class="btn btn-primary" @click="startApplyingDomain">Check</button>
                <button class="btn btn-secondary" @click="abandonDomain">Remove</button>
              </template>
              <template v-if="['applied'].includes(client.custom_domain.state)">
                <button class="btn btn-primary" @click="startRemovingDomain">Remove</button>
              </template>
              <template v-if="['removed'].includes(client.custom_domain.state)">
                <button class="btn btn-primary" @click="abandonDomain">Add new domain</button>
              </template>
              <template v-else-if="['applying_failed'].includes(client.custom_domain.state)">
                <button class="btn btn-primary" @click="startApplyingDomain">Try again</button>
              </template>
              <template v-else-if="['removing_failed'].includes(client.custom_domain.state)">
                <button class="btn btn-primary" @click="startRemovingDomain">Try again</button>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <custom-domain-creator :client-id="clientId" @input="onNewDomainCreation" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerOverlay from "./spinner-overlay";
import CustomDomainCreator from "./custom-domain-manager/custom-domain-creator";

export default {
  components: {
    CustomDomainCreator,
    SpinnerOverlay,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      client: null,

      isLoading: false,
    };
  },
  computed: {},
  created() {
    this.loadState();
  },
  methods: {
    async loadState() {
      const response = await $.get(`/api/clients/${this.clientId}`);
      this.client = response.client;
    },
    onNewDomainCreation(customDomain) {
      this.client.custom_domain = customDomain;
    },
    startApplyingDomain() {
      this.isLoading = true;
      (async () => {
        try {
          const response = await $.post(`/api/clients/${this.clientId}/custom_domain/start_apply`);
          this.client.custom_domain = response.custom_domain;
          this.isLoading = false;
        } catch {
          window.location.reload();
        }
      })();
    },
    startRemovingDomain() {
      this.isLoading = true;
      (async () => {
        try {
          const response = await $.post(`/api/clients/${this.clientId}/custom_domain/start_remove`);
          this.client.custom_domain = response.custom_domain;
          this.isLoading = false;
        } catch {
          window.location.reload();
        }
      })();
    },
    abandonDomain() {
      this.isLoading = true;
      (async () => {
        try {
          await $.ajax({
            method: "DELETE",
            url: `/api/clients/${this.clientId}/custom_domain`,
          });
          this.client.custom_domain = null;
          this.isLoading = false;
        } catch {
          window.location.reload();
        }
      })();
    },
  },
};
</script>
