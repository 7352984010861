<template lang="slm">
  .disciple-card.text-card
    .row
      .col-4.header-column
        .disciple-card__header
          slot name="header"
      .col-8
        input.form-control type="text" v-model="text"
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  padding: 1.75rem 0;
}

.header-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
