<template>
  <div v-if="client && colorConfiguration" class="disciple-card color-card">
    <spinner-overlay v-if="isLoading" />

    <h4 class="disciple-card__header">Choose your theme</h4>
    <p>Choose the colour scheme you want to use here and it’ll be live within 10 minutes!</p>

    <button v-b-modal="'mobile-preview-modal'" class="btn btn-secondary d-lg-none mb-3 w-100">
      Preview your theme
    </button>
    <custom-b-modal id="mobile-preview-modal" hide-footer title="Theme preview">
      <div class="row">
        <div class="col-auto mx-auto">
          <community-preview
            v-if="masterColors"
            :community-url="`https://${client.hostname}`"
            :logo-url="client.image_url"
            :master-colors="masterColors"
          />
        </div>
      </div>
    </custom-b-modal>

    <div class="row">
      <div class="col col-lg-6">
        <h5>Presets</h5>

        <div class="row">
          <div v-for="scheme in colorSchemes" :key="scheme.name" class="col-6 col-md-4 mb-3">
            <color-scheme v-model="selectedColorScheme" :scheme="scheme" />
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-auto d-flex align-items-center">
            <h5 class="mb-0">Customise</h5>
          </div>
          <div class="col-auto ml-auto border p-2 rounded" style="height: 48px">
            <div class="row h-100">
              <div class="col d-flex align-items-center" style="font-size: 12px; line-height: 15px">
                <p v-if="advancedMode" class="font-weight-bold mb-0">Advanced mode is on</p>
                <template v-else>
                  Feeling adventurous?
                  <br />
                  Turn on advanced mode
                </template>
              </div>
              <div class="col-auto d-flex align-items-center">
                <disciple-switch v-model="advancedMode" hide-label label="Advanced Mode" class="ccm__switch" />
              </div>
            </div>
          </div>
        </div>

        <template v-if="advancedMode">
          <p class="font-weight-bold mt-5">Post theme</p>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Post background"
            label-for="advanced-post-background"
          >
            <color-input
              id="advanced-post-background"
              v-model="$v.colorConfiguration.advanced_post_background.$model"
              :state="$v.colorConfiguration.advanced_post_background | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Post text"
            label-for="advanced-post-text"
          >
            <color-input
              id="advanced-post-text"
              v-model="$v.colorConfiguration.advanced_post_text.$model"
              :state="$v.colorConfiguration.advanced_post_text | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Secondary text"
            label-for="advanced-post-detail"
          >
            <color-input
              id="advanced-post-detail"
              v-model="$v.colorConfiguration.advanced_post_detail.$model"
              :state="$v.colorConfiguration.advanced_post_detail | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <p class="font-weight-bold mt-5">Background</p>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Background"
            label-for="advanced-wall-background"
          >
            <color-input
              id="advanced-wall-background"
              v-model="$v.colorConfiguration.advanced_wall_background.$model"
              :state="$v.colorConfiguration.advanced_wall_background | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Header background"
            label-for="advanced-top-bar-background"
          >
            <color-input
              id="advanced-top-bar-background"
              v-model="$v.colorConfiguration.advanced_top_bar_background.$model"
              :state="$v.colorConfiguration.advanced_top_bar_background | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <p class="font-weight-bold mt-5">Text</p>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Header text"
            label-for="advanced-post-tint"
          >
            <color-input
              id="advanced-top-bar-text"
              v-model="$v.colorConfiguration.advanced_top_bar_text.$model"
              :state="$v.colorConfiguration.advanced_top_bar_text | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Background text"
            label-for="advanced-wall-text"
          >
            <color-input
              id="advanced-wall-text"
              v-model="$v.colorConfiguration.advanced_wall_text.$model"
              :state="$v.colorConfiguration.advanced_wall_text | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Secondary background text"
            label-for="advanced-wall-detail"
          >
            <color-input
              id="advanced-wall-detail"
              v-model="$v.colorConfiguration.advanced_wall_detail.$model"
              :state="$v.colorConfiguration.advanced_wall_detail | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <p class="font-weight-bold mt-5">Buttons</p>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Header buttons"
            label-for="advanced-top-bar-tint"
          >
            <color-input
              id="advanced-top-bar-tint"
              v-model="$v.colorConfiguration.advanced_top_bar_tint.$model"
              :state="$v.colorConfiguration.advanced_top_bar_tint | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Post buttons"
            label-for="advanced-post-tint"
          >
            <color-input
              id="advanced-post-tint"
              v-model="$v.colorConfiguration.advanced_post_tint.$model"
              :state="$v.colorConfiguration.advanced_post_tint | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Background buttons"
            label-for="advanced-wall-tint"
          >
            <color-input
              id="advanced-wall-tint"
              v-model="colorConfiguration.advanced_wall_tint"
              :state="$v.colorConfiguration.advanced_wall_tint | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>
        </template>
        <template v-else>
          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Post theme"
            label-for="simple-theme"
            class="mt-4"
          >
            <b-form-select
              id="simple-theme"
              v-model="$v.colorConfiguration.simple_theme.$model"
              :options="[
                { value: 'light', text: 'Light' },
                { value: 'dark', text: 'Dark' },
              ]"
              :state="$v.colorConfiguration.simple_theme | dirtyValidation"
            />
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-md="9"
            label-cols-lg="8"
            label="Background"
            label-for="simple-primary"
          >
            <color-input
              id="simple-primary"
              v-model="$v.colorConfiguration.simple_primary.$model"
              :state="$v.colorConfiguration.simple_primary | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group label-cols="6" label-cols-md="9" label-cols-lg="8" label="Text" label-for="simple-secondary">
            <color-input
              id="simple-secondary"
              v-model="$v.colorConfiguration.simple_secondary.$model"
              :state="$v.colorConfiguration.simple_secondary | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>

          <b-form-group label-cols="6" label-cols-md="9" label-cols-lg="8" label="Buttons" label-for="simple-action">
            <color-input
              id="simple-action"
              v-model="$v.colorConfiguration.simple_action.$model"
              :state="$v.colorConfiguration.simple_action | dirtyValidation"
              @show-popover="hideAllPopovers"
            />
          </b-form-group>
        </template>

        <button class="btn btn-primary" @click="onPublish">Publish</button>
        <button class="btn btn-secondary ml-3" @click="onCancel">Undo</button>
      </div>
      <div class="col d-none d-lg-flex justify-content-end">
        <community-preview
          v-if="masterColors"
          :community-url="`https://${client.hostname}`"
          :logo-url="client.image_url"
          :master-colors="masterColors"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf, helpers } from "vuelidate/lib/validators";
import _ from "lodash";

import SpinnerOverlay from "./spinner-overlay";
import ColorScheme from "./presales-onboarding/color-scheme";
import CommunityPreview from "./community-preview";
import DiscipleSwitch from "./disciple-switch";
import ColorInput from "./color-input";

const mustBeColor = helpers.regex("mustBeColor", /^#[0-9a-fA-F]{6}$/);

export default {
  components: {
    SpinnerOverlay,
    ColorScheme,
    CommunityPreview,
    DiscipleSwitch,
    ColorInput,
  },
  mixins: [validationMixin],
  props: {
    clientId: {
      type: String,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,

      client: null,
      colorConfiguration: null,
    };
  },
  computed: {
    advancedMode: {
      get() {
        return !this.colorConfiguration.simple_enabled;
      },
      set(newVal) {
        if (newVal) {
          this.isLoading = true;
          (async () => {
            const masterColors = await this.fetchColorPreview({
              ...this.colorConfiguration,
            });

            Object.keys(this.colorConfiguration)
              .filter((k) => k.startsWith("advanced_"))
              .forEach((k) => {
                this.$v.colorConfiguration[k].$model = masterColors[k.replace(/^advanced_/, "")];
              });

            this.$v.colorConfiguration.simple_enabled.$model = false;
            this.isLoading = false;
          })();
        } else {
          this.$v.colorConfiguration.simple_enabled.$model = true;
        }

        this.hideAllPopovers();
      },
    },
    selectedColorScheme: {
      get() {
        if (!this.colorConfiguration.simple_enabled) {
          return;
        }

        return _.find(this.colorSchemes, (scheme) => {
          return (
            scheme.primaryColor === this.colorConfiguration.simple_primary &&
            scheme.secondaryColor === this.colorConfiguration.simple_secondary &&
            scheme.actionColor === this.colorConfiguration.simple_action &&
            scheme.theme === this.colorConfiguration.simple_theme
          );
        });
      },
      set(newVal) {
        this.$v.colorConfiguration.simple_enabled.$model = true;
        this.$v.colorConfiguration.simple_primary.$model = newVal.primaryColor;
        this.$v.colorConfiguration.simple_secondary.$model = newVal.secondaryColor;
        this.$v.colorConfiguration.simple_action.$model = newVal.actionColor;
        this.$v.colorConfiguration.simple_theme.$model = newVal.theme;
      },
    },
    colorSchemes() {
      return require("static/color_schemes.json");
    },
  },
  asyncComputed: {
    async masterColors() {
      if (!this.colorConfiguration) {
        return null;
      }

      return await this.fetchColorPreview(this.colorConfiguration);
    },
  },
  created() {
    this.loadClientState();
    this.loadColorConfigurationState();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => {
      if (!this.$v.$anyDirty) {
        return;
      }

      e.preventDefault();
      e.returnValue = "You have unpublished changes. Are you sure you want to leave this page?";
    });
  },
  methods: {
    hideAllPopovers() {
      this.$root.$emit("bv::hide::popover");
    },
    async fetchColorPreview(colorConfiguration) {
      const response = await $.get("/api/color_preview", {
        colors: colorConfiguration,
      });

      if (response.result === "failure") {
        return null;
      }

      return response.master_colors;
    },
    async loadClientState() {
      const response = await $.get(`/api/clients/${this.clientId}`);
      this.client = response.client;
    },
    async loadColorConfigurationState() {
      const response = await $.get(`/api/clients/${this.clientId}/color_configuration`);
      this.colorConfiguration = response.color_configuration;
    },
    async saveColorConfigurationState() {
      const response = await $.ajax({
        method: "PATCH",
        url: `/api/clients/${this.clientId}/color_configuration`,
        data: {
          color_configuration: this.colorConfiguration,
        },
      });
      if (response.color_configuration) {
        this.colorConfiguration = response.color_configuration;
      }
    },
    onPublish() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;
      (async () => {
        await this.saveColorConfigurationState();

        this.$v.$reset();
        this.$bvToast.toast("Your changes will go live in 10 minutes", {
          variant: "success",
          title: "Changes saved",
        });
        this.isLoading = false;
      })();
    },
    onCancel() {
      this.isLoading = true;
      (async () => {
        await this.loadColorConfigurationState();

        this.$v.$reset();

        this.isLoading = false;
      })();
    },
  },
  validations: {
    colorConfiguration: {
      simple_enabled: {},
      simple_primary: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => colorConfiguration.simple_enabled),
      },
      simple_secondary: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => colorConfiguration.simple_enabled),
      },
      simple_action: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => colorConfiguration.simple_enabled),
      },
      simple_theme: {},
      advanced_post_background: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_post_detail: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_post_text: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_post_tint: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_top_bar_background: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_top_bar_text: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_top_bar_tint: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_wall_background: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_wall_detail: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_wall_text: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
      advanced_wall_tint: {
        mustBeColor,
        required: requiredIf((colorConfiguration) => !colorConfiguration.simple_enabled),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ccm {
  &__switch {
    & ::v-deep,
    & ::v-deep label {
      margin-bottom: 0;
    }
  }
}
</style>
