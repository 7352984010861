<template>
  <div id="session-monitor">
    <custom-b-modal
      id="sessionExpirationWarningModal"
      :visible="showWarningModal"
      title="Your session is about to expire."
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      @ok="refreshSession"
    >
      <p>Due to inactivity you will soon be logged out automatically. Do you wish to stay logged in?</p>
    </custom-b-modal>
    <custom-b-modal
      id="timedoutWarningModal"
      :visible="showTimedoutModal"
      title="Your session has expired."
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      @ok="redirectToSignIn"
    >
      <p>Due to inactivity you have been logged out automatically. Do you wish to log in again?</p>
    </custom-b-modal>
    <custom-b-modal
      id="loggedOutWarningModal"
      :visible="showLoggedOutModal"
      title="You have been logged out."
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      @ok="redirectToSignIn"
    >
      <p>You have been logged out. Do you wish to log in again?</p>
    </custom-b-modal>
  </div>
</template>

<script>
import camelize from "camelize";

export default {
  data() {
    return {
      session: {
        state: null,
        timeoutIn: null,
      },
      signInUrl: null,
      sessionUrl: null,
      refreshSessionUrl: null,
    };
  },
  computed: {
    showWarningModal() {
      return this.session.state === "logged_in" && this.session.timeoutIn < 60;
    },
    showTimedoutModal() {
      return this.session.state === "timedout";
    },
    showLoggedOutModal() {
      return this.session.state === "logged_out";
    },
  },
  beforeMount() {
    this.signInUrl = this.$el.attributes["data-sign-in-url"].value;
    this.sessionUrl = this.$el.attributes["data-session-url"].value;
    this.refreshSessionUrl = this.$el.attributes["data-refresh-session-url"].value;
  },
  mounted() {
    window.setInterval(() => {
      this.updateCurrentState();
    }, 10000);
    this.updateCurrentState();
  },
  methods: {
    updateCurrentState() {
      $.ajax({ url: this.sessionUrl, timeout: 8000 }).done((data) => {
        this.session = camelize(data);
      });
    },
    refreshSession() {
      $.post(this.refreshSessionUrl).done(() => this.updateCurrentState());
    },
    redirectToSignIn() {
      window.location = `${this.signInUrl}?next=${window.location.pathname}`;
    },
  },
};
</script>
