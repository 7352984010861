<template>
  <div class="d-flex justify-content-center">
    <div
      class="branded-iphone__image"
      :style="{
        'background-image': `url(${require('./branded-iphone/assets/branded_iphone.jpg')})`,
      }"
    >
      <div class="branded-iphone__icon">
        <img :src="client.image_url" width="40" height="40" />
        <span>{{ client.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.branded-iphone {
  &__image {
    height: 238px;
    width: 245px;

    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
  }

  &__icon {
    width: 40px;

    position: absolute;
    bottom: 19px;
    right: 28px;

    font-size: 7px;
    line-height: 9px;
    font-weight: bold;
    color: white;
    text-align: center;

    display: flex;
    flex-direction: column;

    img {
      border-radius: 5px;
    }

    span {
      margin-top: 1px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
