/* eslint no-console: 0 */

import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import VueBus from "vue-bus";
import VueMq from "vue-mq";
import VueTransmit from "vue-transmit";
import AsyncComputed from "vue-async-computed";

import "../directives";
import "../filters";

import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;

Vue.use(BootstrapVue, {
  BTooltip: { customClass: "custom-b-tooltip" },
});
import "app_manager/custom-b-vue-styles.scss";
Vue.use(VueTransmit);
Vue.use(VueBus);
Vue.use(VueMq);
Vue.use(AsyncComputed);

import CustomBModal from "app_manager/components/custom-b-modal";

Vue.component("custom-b-modal", CustomBModal);

import AppEditor from "app_manager/components/app-editor.vue";
import AppleAccountEditor from "app_manager/components/apple-account-editor";
import ApplicationsSearch from "app_manager/components/applications-search";
import InvitationModalSubmitButton from "app_manager/components/invitation-modal-submit-button";
import LiquidPlayground from "app_manager/components/liquid-playground";
import PresalesOnboarding from "app_manager/components/presales-onboarding";
import SessionMonitor from "app_manager/components/session-monitor.vue";
import RegistrationForm from "app_manager/components/registration-form.vue";
import Livestreaming from "app_manager/components/livestreaming";
import BookDemoButton from "app_manager/components/book-demo-button";
import AppStoreConnectCredentialsCard from "app_manager/components/app-store-connect-credentials-card";
import ChatBot from "app_manager/components/chat-bot";
import MFAPasskeyManager from "app_manager/components/mfa/passkey-manager";
import MFAVerifierManager from "app_manager/components/mfa/verifier-manager";

import AppHeader from "app_manager/components/navigation/app-header";
import SideNavigation from "app_manager/components/navigation/side-navigation";
import NavigationCategoriesDesktop from "app_manager/components/navigation/navigation-categories-desktop";
import SideNavigationMobile from "app_manager/components/navigation/side-navigation-mobile";
import NavigationCategories from "app_manager/components/navigation/navigation-categories";
import NavigationLinks from "app_manager/components/navigation/navigation-links";
import NavigationLinksDrawer from "app_manager/components/navigation/navigation-links-drawer";

Vue.component("side-navigation", SideNavigation);
Vue.component("navigation-categories-desktop", NavigationCategoriesDesktop);
Vue.component("side-navigation-mobile", SideNavigationMobile);
Vue.component("navigation-categories", NavigationCategories);
Vue.component("navigation-links", NavigationLinks);
Vue.component("navigation-links-drawer", NavigationLinksDrawer);

const mountComponent = function mountComponent(component, id) {
  const el = document.getElementById(id);
  if (el) {
    new Vue(component).$mount(el);
  }
};

document.addEventListener("DOMContentLoaded", () => {
  mountComponent(AppEditor, "app-editor");
  mountComponent(AppHeader, "app-header");
  mountComponent(SideNavigation, "side-navigation");
  mountComponent(AppleAccountEditor, "apple-account-editor");
  mountComponent(ApplicationsSearch, "applications-search");
  mountComponent(InvitationModalSubmitButton, "invitation-modal-submit-button");
  mountComponent(LiquidPlayground, "liquid-playground");
  mountComponent(PresalesOnboarding, "presales-onboarding");
  mountComponent(SessionMonitor, "session-monitor");
  mountComponent(RegistrationForm, "registration-form");
  mountComponent(Livestreaming, "livestreaming");
  mountComponent(BookDemoButton, "book-demo-button");
  mountComponent(AppStoreConnectCredentialsCard, "app-store-connect-credentials-card");
  mountComponent(ChatBot, "chat-bot");
  mountComponent(MFAPasskeyManager, "mfa-passkey-manager");
  mountComponent(MFAVerifierManager, "mfa-verifier-manager");
});
