<template>
  <div class="row shadow-1 top-bar__container">
    <div class="col d-flex align-items-center">
      <img src="./assets/disciple_logo.svg" height="36" class="ml-3" />
    </div>
    <steps :steps="steps" :current-step="currentStep" class="col d-md-none justify-content-end" />
  </div>
</template>

<script>
import Steps from "./steps";

export default {
  components: {
    Steps,
  },
  props: {
    steps: {
      type: Number,
      default: null,
      required: true,
    },
    currentStep: {
      type: Number,
      default: null,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  &__container {
    height: 64px;

    position: fixed;
    left: 0;
    right: 0;

    background: #f5f5f5;
    z-index: 1;
  }
}
</style>
