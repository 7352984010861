<template>
  <div class="cpbn__container" :style="{ 'background-color': masterColors.top_bar_background }">
    <svg class="cpbn__icon" fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m17.7689 7.08467c-.0106-.01059-.0211-.01059-.0316-.02119h-.0212l-1.6166-1.34451-6.66708-5.558185c-.25711-.2143535-.63026-.214383-.88741-.00007l-6.65658 5.547655-1.62719 1.35511c-.2958113.23295-.3486279.66699-.116209.96346.232418.2964.665656.34932.961469.11644.01057-.0106.02115-.0106.03173-.0212l.16902-.13763.32754-.27521v.01053l6.84674-5.53699c.29581-.23289.71848-.23289 1.01429 0l6.86778 5.52646.2642.22228.2325.19056c.2852.25408.7184.22235.972-.06352.243-.2858.2219-.71991-.0634-.97399zm-8.29417-3.12318c-.29588-.23288-.71847-.23288-1.01435 0l-6.19284 4.97568c-.39999.32138-.63275.80733-.63275 1.32103v6.016c.01058.9529.78193 1.7257 1.73282 1.7257h2.11318c.95117 0 1.72225-.7726 1.72225-1.7257l-.00001-2.0055c0-.9029.60909-1.7458 1.49758-1.8961 1.12777-.1908 2.11599.6874 2.11599 1.7842v2.1174c0 .9423.7607 1.7151 1.7011 1.7257h2.1132c.9509.0106 1.7222-.7622 1.7327-1.7151v-5.9738c0-.51366-.2326-.99952-.6325-1.3209z"
        :fill="masterColors.top_bar_text"
      />
    </svg>

    <svg class="cpbn__icon" fill="none" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
      <g :fill="masterColors.top_bar_text">
        <path
          d="m18.3049 7.00873c-.8663-4.06124-4.5675-7.00873-8.82002-7.00873-2.44121 0-4.73617.94503-6.43493 2.65498-1.65377 1.65379-2.565014 3.84755-2.553753 6.15379 0 1.69873.495 3.35243 1.451213 4.79253.02253.0337.04505.0787.0675.1012.05631.1125.07876.18.09002.2137l-.77625 2.8238c-.02252.0788-.04497.1688-.05623.3038 0 .5287.4275.9562 1.01252.9562.12373-.0113.24746-.045.32622-.0788l2.7787-1.0124c.15752-.0676.34875-.0676.51752 0 .14626.0562.72002.27 1.28251.4274.51746.1463 1.59747.36 2.44122.36 4.89366 0 8.86496-3.9824 8.86496-8.87617 0-.60754-.0675-1.21502-.1912-1.8113zm-8.67376 9.56247c-.64126 0-1.58621-.1687-2.13752-.3262-.51745-.1463-1.04623-.3375-1.18123-.3937-.43869-.1688-.95621-.1463-1.33874.0112l-2.48619.9225.71994-2.61c.07876-.2925 0-.6412-.22494-1.035-.03379-.0675-.07876-.135-.11254-.18-.82123-1.2375-1.24873-2.6662-1.24873-4.15123-.01126-2.01379.77625-3.91505 2.2162-5.35501 1.49627-1.50753 3.49879-2.32876 5.64749-2.32876 3.72372 0 6.96372 2.57623 7.71742 6.12.1126.51753.1688 1.04624.1688 1.57503 0 4.27497-3.465 7.75117-7.73996 7.75117z"
        />
        <path
          d="m6.84118 9c0 .68623-.55124 1.2375-1.23754 1.2375-.68623 0-1.24872-.55127-1.24872-1.2375s.56249-1.23747 1.24872-1.23747c.6863 0 1.23754.55124 1.23754 1.23747z"
        />
        <path
          d="m9.49615 10.2375c.68345 0 1.23745-.55406 1.23745-1.2375s-.554-1.23747-1.23745-1.23747c-.68344 0-1.23747.55403-1.23747 1.23747s.55403 1.2375 1.23747 1.2375z"
        />
        <path
          d="m14.6374 9c0 .68623-.5625 1.2375-1.2488 1.2375s-1.2375-.55127-1.2375-1.2375.5512-1.23747 1.2375-1.23747 1.2488.55124 1.2488 1.23747z"
        />
      </g>
    </svg>

    <svg class="cpbn__icon" fill="none" height="18" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
      <g :fill="masterColors.top_bar_text">
        <path
          d="m9.99219 0c-4.96122 0-9.000002 4.03878-9.000002 9 0 4.9612 4.038782 9 9.000002 9 4.96121 0 9.00001-4.0388 9.00001-9 0-4.96122-4.0388-9-9.00001-9zm0 16.875c-1.41751 0-2.74501-.3713-3.89253-1.035l.87754-2.3175c.14625-.3938.52871-.6525.95622-.6525h4.11758c.4275 0 .8099.2587.9562.6525l.8775 2.3175c-1.1475.6637-2.475 1.035-3.89251 1.035zm4.86001-1.6875-.7875-2.0588c-.315-.8325-1.125-1.3837-2.0137-1.3837h-4.11758c-.88873 0-1.6875.5512-2.00247 1.3725l-.79877 2.07c-1.83376-1.44-3.01499-3.6788-3.01499-6.1875 0-4.34248 3.53251-7.875 7.875-7.875 4.34251 0 7.87501 3.53252 7.87501 7.875 0 2.5087-1.1812 4.7475-3.015 6.1875z"
        />
        <path
          d="m9.99205 3.8584c-1.93497 0-3.50999 1.57503-3.50999 3.50999 0 1.93504 1.57502 3.51001 3.50999 3.51001 1.93495 0 3.50995-1.57497 3.50995-3.51001 0-1.93496-1.575-3.50999-3.50995-3.50999zm0 5.89499c-1.31623 0-2.38499-1.06877-2.38499-2.385s1.06876-2.38499 2.38499-2.38499c1.31625 0 2.38495 1.06876 2.38495 2.38499s-1.0687 2.385-2.38495 2.385z"
        />
      </g>
    </svg>

    <svg class="cpbn__icon" fill="none" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m17.1035 14.4675c-.1028-.0338-2.4443-1.0013-2.4443-3.33v-4.25251c0-2.08129-1.2679-3.88126-3.084-4.66877v-.135c0-1.147519-.948-2.08122-2.11311-2.08122-1.16508 0-2.11307.933701-2.11307 2.08122v.135c-1.81616.78751-3.084 2.58748-3.084 4.66877v4.25251c0 2.3287-2.34153 3.2962-2.44436 3.33-.25126.1125-.39975.3713-.34266.63.04567.27.28556.4612.55968.4612h4.43181c.27412 1.3951 1.50773 2.4413 2.9926 2.4413 1.48491 0 2.71851-1.0462 2.99261-2.4413h4.4318c.2741 0 .514-.1912.5711-.4612.0457-.2587-.1028-.5175-.3541-.63zm-7.64141-13.3425c.45692 0 .83381.3037.93661.72002-.3084-.04505-.61676-.07876-.93661-.07876s-.62821.03371-.93663.07876c.10283-.41632.47972-.72002.93663-.72002zm0 15.75c-.85666 0-1.57626-.5512-1.81616-1.3163h3.63227c-.2398.7651-.9594 1.3163-1.81611 1.3163zm-5.55116-2.4413c.76527-.6975 1.4963-1.7775 1.4963-3.2962v-4.25251c0-1.44003.7767-2.71128 1.94179-3.39752.18272-.12373.36544-.21375.57111-.29251.18272-.07876.37688-.14625.5711-.19123.30835-.0675.63964-.11247.97086-.11247s.66251.04497.97081.11247c.1943.04498.3884.11247.5712.19123.2056.07876.3883.16878.5711.29251 1.165.68624 1.9417 1.95749 1.9417 3.39752v4.25251c0 1.5187.7311 2.5987 1.4963 3.2962z"
        :fill="masterColors.top_bar_text"
      />
    </svg>

    <svg class="cpbn__icon" fill="none" height="18" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1.21622 3.34271c-.04805-.04774-.09009-.10145-.12613-.16112-.03604-.05968-.06006-.11936-.07207-.17903-.01201-.07161-.01802-.14919-.01802-.23274 0-.22677.07207-.41176.21622-.55499.14414-.14322.33033-.21483.55855-.21483h16.45043c.2283 0 .4144.07161.5586.21483.1441.14323.2162.32822.2162.55499s-.0721.41773-.2162.57289c-.1442.14322-.3303.21483-.5586.21483h-16.45043c-.22822 0-.41441-.07161-.55855-.21483zm17.00898 4.88747c.2283 0 .4144.07161.5586.21483.1441.14322.2162.32822.2162.55499s-.0721.41176-.2162.55499c-.1442.14322-.3303.21483-.5586.21483h-16.45043c-.22822 0-.41441-.07161-.55855-.21483-.02403-.02387-.04805-.05371-.07208-.08952-.02402-.0358-.04804-.07161-.07207-.10741-.01201-.03581-.02402-.07162-.03603-.10742-.01202-.03581-.02403-.07161-.03604-.10742 0-.04774 0-.09548 0-.14322 0-.22677.07207-.41177.21622-.55499.14414-.14322.33033-.21483.55855-.21483zm.5586 6.42712c.1441.1432.2162.3282.2162.555 0 .2267-.0721.4177-.2162.5729-.1442.1432-.3303.2148-.5586.2148h-16.45043c-.22822 0-.41441-.0716-.55855-.2148-.02403-.0239-.04805-.0478-.07208-.0716-.01201-.0359-.03003-.0657-.05405-.0896-.01201-.0358-.02402-.0656-.03604-.0895-.01201-.0358-.02402-.0716-.03603-.1074s-.01802-.0656-.01802-.0895c0-.0358 0-.0776 0-.1253 0-.2268.07207-.4118.21622-.555.14414-.1432.33033-.2148.55855-.2148h16.45043c.2162 0 .4024.0716.5586.2148z"
        :fill="masterColors.top_bar_text"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpbn {
  &__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    height: 39px;

    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.05);

    display: flex;
    align-items: center;
  }

  &__icon {
    &:first-child {
      margin-left: 34px;
    }

    &:last-child {
      margin-right: 35px;
    }

    & + & {
      margin-left: 32px;
    }
  }
}
</style>
