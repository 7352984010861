<template>
  <spinner-overlay v-if="state === STATES.LOADING" />
  <webauthn-verifier v-else-if="state === STATES.WEBAUTHN" :options="options" @switch-to-email="state = STATES.EMAIL" />
  <email-verifier v-else-if="state === STATES.EMAIL" :options="options" @switch-to-webauthn="state = STATES.WEBAUTHN" />
</template>

<script>
import { stateMixin } from "utils/vue";
import SpinnerOverlay from "app_manager/components/spinner-overlay";
import EmailVerifier from "app_manager/components/mfa/email-verifier";
import WebauthnVerifier from "app_manager/components/mfa/webauthn-verifier";

export default {
  components: {
    SpinnerOverlay,
    EmailVerifier,
    WebauthnVerifier,
  },
  mixins: [
    stateMixin({
      states: ["LOADING", "WEBAUTHN", "EMAIL"],
    }),
  ],
  data() {
    return {
      options: null,
    };
  },
  beforeMount() {
    this.options = JSON.parse(this.$el.dataset.verificationOptions);

    if (typeof PublicKeyCredential !== "undefined" && this.options.available_methods.includes("webauthn")) {
      this.state = this.STATES.WEBAUTHN;
    } else {
      this.state = this.STATES.EMAIL;
    }
  },
};
</script>
