<template>
  <b-dropdown-item
    v-if="link.accessible"
    :key="link.label"
    :href="link.url"
    :target="link.external_link ? '_blank' : null"
    :data-method="link.data_method"
  >
    <span>{{ link.label }}</span>
    <ion-icon v-if="link.external_link" class="ml-3 mr-1" name="open-outline" />
  </b-dropdown-item>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dropdown-item {
  border-radius: 0;

  &:hover {
    background-color: var(--color-light-gray);
  }
}

ion-icon {
  height: 20px;
  width: 20px;
  color: var(--color-deep-blue-grey);
}
</style>
