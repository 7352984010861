<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="row">
    <div class="col-md-6 order-md-1 order-2">
      <div class="disciple-card livestream__preview">
        Upgrade to a mobile app to access livestreaming!
        <div
          class="livestream__preview-image mx-2 mb-1"
          :style="{
            'background-image': `url(${require('./livestreaming/assets/livestream_preview.png')})`,
          }"
        />
      </div>
    </div>
    <div class="col-12 col-md-5 order-md-2 order-1">
      <div class="disciple-card">
        <h4>Looking for a Mobile App?</h4>
        <p>Explore the option to have a branded iOS and Android app for your community.</p>

        <branded-iphone :client="client" class="mb-5" />

        <book-demo-button class="w-100" />
      </div>
    </div>
  </div>
</template>

<script>
import BrandedIphone from "app_manager/components/branded-iphone";
import BookDemoButton from "app_manager/components/book-demo-button";

export default {
  components: {
    BrandedIphone,
    BookDemoButton,
  },
  beforeMount() {
    this.client = JSON.parse(this.$el.dataset.client);
  },
};
</script>

<style lang="scss" scoped>
.livestream {
  &__preview-image {
    height: 267px;
    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
  }
}
</style>
