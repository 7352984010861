<template>
  <b-sidebar v-model="sideNavigationDisplayed" class="px-2" no-header width="100%">
    <div class="scrollable-wrapper">
      <navigation-categories class="navigation-categories-mobile h-100" :menu-items="menuItems" />
    </div>
    <div class="d-flex flex-grow-1 scrollable-wrapper">
      <navigation-links class="navigation-links-mobile d-flex align-items-start" :menu-items="menuItems" />
    </div>
  </b-sidebar>
</template>

<script>
import NavigationCategories from "app_manager/components/navigation/navigation-categories";
import NavigationLinks from "app_manager/components/navigation/navigation-links";

export default {
  components: {
    NavigationCategories,
    NavigationLinks,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sideNavigationDisplayed: false,
    };
  },
  watch: {
    $route() {
      this.sideNavigationDisplayed = false;
    },
    sideNavigationDisplayed: {
      immediate: true,
      async handler(val) {
        await this.$nextTick();
        document.documentElement.style.overflowY = val ? "hidden" : null;
      },
    },
  },
  created() {
    this.$bus.$on("toggle-mobile-nav-displayed", this.onNavDisplayedToggled);
  },
  destroyed() {
    this.$bus.$off("toggle-mobile-nav-displayed", this.onNavDisplayedToggled);
  },
  methods: {
    onNavDisplayedToggled() {
      this.sideNavigationDisplayed = !this.sideNavigationDisplayed;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  margin-top: 64px !important;

  &-body {
    display: flex;
  }
}

.navigation-categories-mobile {
  border-right: 1px solid var(--color-light-gray);
  background-color: var(--color-ash);
}

.scrollable-wrapper {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}

::v-deep .navbar-nav {
  max-height: calc(100vh - 64px);
}
</style>
