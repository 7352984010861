<template>
  <div class="cph__container" :style="{ 'background-color': masterColors.top_bar_background }">
    <div class="mr-auto" style="width: 19px" />
    <img :src="appIconUrl" class="cph__app-icon" width="30" height="30" />
    <div class="ml-auto">
      <svg fill="none" height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
        <g clip-rule="evenodd" :fill="masterColors.top_bar_text" fill-rule="evenodd">
          <path
            d="m8.90625 17.5837c4.26295 0 7.71875-3.4558 7.71875-7.71879 0-4.26295-3.4558-7.71875-7.71875-7.71875s-7.71875 3.4558-7.71875 7.71875c0 4.26299 3.4558 7.71879 7.71875 7.71879zm0 1.1875c4.91875 0 8.90625-3.9875 8.90625-8.90629 0-4.91878-3.9875-8.906249-8.90625-8.906249-4.91879 0-8.90625 3.987469-8.90625 8.906249 0 4.91879 3.98746 8.90629 8.90625 8.90629z"
          />
          <path
            d="m14.4239 15.3826c.2319-.2319.6078-.2319.8397 0l3.5625 3.5625c.2319.2318.2319.6078 0 .8397-.2319.2318-.6078.2318-.8397 0l-3.5625-3.5625c-.2319-.2319-.2319-.6079 0-.8397z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoUrl: {
      type: String,
      default: null,
    },
    masterColors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    appIconUrl() {
      return this.logoUrl || require("./assets/default_app_icon.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.cph {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    padding: 3px 9px 4px 9px;

    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 4px 4px rgba(0, 0, 0, 0.05);
  }

  &__app-icon {
    border-radius: 3px;
  }
}
</style>
