<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="cpp__container" :style="{ 'background-color': masterColors.post_background }">
    <div class="cpp__header">
      <div class="cpp__header-left">
        <img :src="require(`${avatarPath}`)" class="cpp__avatar" height="37" width="37" />
        <div class="cpp__info">
          <span class="cpp__author-name" :style="{ color: masterColors.post_text }">{{ userName }}</span>
          <div class="cpp__post-info">
            <span class="cpp__date" :style="{ color: masterColors.post_detail }">{{ date }}</span>
            <span class="cpp__group-name" :style="{ color: masterColors.post_text }">in {{ groupName }}</span>
          </div>
        </div>
      </div>
      <div class="cpp__header-right">
        <div class="cpp__option-dots">
          <span class="cpp__option-dot" :style="{ 'background-color': masterColors.post_detail }" />
          <span class="cpp__option-dot" :style="{ 'background-color': masterColors.post_detail }" />
          <span class="cpp__option-dot" :style="{ 'background-color': masterColors.post_detail }" />
        </div>
      </div>
    </div>

    <div class="cpp__text-content" :style="{ color: masterColors.post_text }">
      <slot />
    </div>

    <img v-if="photoPath" :src="require(`${photoPath}`)" class="cpp__photo" />

    <div class="cpp__actions">
      <div class="cpp__action">
        <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
          <g fill="#7b8385">
            <path
              d="m.159358 5.84061c.721889-3.38436 3.806222-5.84061 7.349982-5.84061 2.03435 0 3.94686.787525 5.36246 2.21249 1.3781 1.37815 2.1375 3.20629 2.1281 5.12815 0 1.41564-.4125 2.79376-1.2093 3.99376-.0188.0281-.0376.0656-.0563.0843-.0469.0938-.0656.1501-.075.1782l.6469 2.3531c.0187.0656.0375.1407.0468.2531 0 .4406-.3562.7969-.8437.7969-.1031-.0094-.2062-.0375-.2719-.0656l-2.3156-.8437c-.1312-.0564-.2906-.0564-.4312 0-.1219.0468-.60004.2249-1.06879.3561-.43121.1219-1.33123.3001-2.03435.3001-4.07808 0-7.38746-3.3188-7.38746-7.39687 0-.50629.0562474-1.01252.159358-1.50942zm7.228102 7.96879c.53438 0 1.32184-.1406 1.78126-.2719.43121-.1219.87188-.2812.98438-.3281.3656-.1406.7968-.1219 1.1156.0093l2.0718.7688-.5999-2.175c-.0657-.2437 0-.5343.1874-.8625.0282-.0563.0657-.1125.0938-.15.6844-1.03127 1.0406-2.22185 1.0406-3.45936.0094-1.67816-.6469-3.26254-1.8468-4.46251-1.2469-1.25627-2.91567-1.94063-4.70626-1.94063-3.1031 0-5.80309 2.14685-6.4312 5.1-.093782.43127-.140645.87187-.140645 1.31253 0 3.56247 2.887495 6.45937 6.449965 6.45937z"
            />
            <g clip-rule="evenodd" fill-rule="evenodd">
              <path
                d="m3.52185 6.24994c0-.25888.20987-.46875.46875-.46875h7.0189c.2589 0 .4688.20987.4688.46875s-.2099.46875-.4688.46875h-7.0189c-.25888 0-.46875-.20987-.46875-.46875z"
              />
              <path
                d="m3.52185 8.74988c0-.25888.20987-.46875.46875-.46875h5.23969c.25888 0 .46875.20987.46875.46875s-.20987.46875-.46875.46875h-5.23969c-.25888 0-.46875-.20987-.46875-.46875z"
              />
            </g>
          </g>
        </svg>
        <span class="cpp__action-text" :style="{ color: masterColors.post_detail }">7</span>
      </div>

      <div class="cpp__action">
        <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="m6.97723.0378074c.17256-.0738051.37252-.0376589.50829.0918806l7.36928 7.031222c.0927.08845.1452.21097.1452.33907 0 .12811-.0525.25062-.1452.33908l-7.36928 7.03124c-.13577.1295-.33573.1657-.50829.0919s-.28447-.2434-.28447-.431v-3.5357c-2.69068.1555-4.70022 1.6474-5.808763 3.7539-.100411.1908-.31808.2886-.527478.2369s-.356519-.2395-.356519-.4551c0-2.9002.557315-5.50742 1.73041-7.40619 1.10896-1.79497 2.76761-2.94871 4.96235-3.09204v-3.56421c0-.187609.11191-.357148.28447-.4309526zm.65345 1.5264126v2.92238c0 .25888-.20996.46875-.46896.46875-2.0671 0-3.59749.98568-4.6333 2.66225-.83677 1.35439-1.3504 3.1645-1.52416 5.2621 1.41872-1.7218 3.52995-2.8351 6.15746-2.8351.259 0 .46896.2099.46896.4688v2.9223l6.22122-5.93572z"
            fill="#7b8385"
            fill-rule="evenodd"
          />
        </svg>
      </div>

      <div class="cpp__action cpp__action--right">
        <span class="cpp__action-text" :style="{ color: masterColors.post_detail }">14</span>
        <svg fill="none" height="15" viewBox="0 0 14 15" width="14" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m14 9.21564c0-.48752-.1941-.97498-.5822-1.29375l.0098-.00939c.3104-.525.2814-1.29375-.068-1.86561-.3104-.51562-.8052-.75938-1.4067-.79686h-2.62928c.20377-.77814.4851-2.20315.33955-3.69375-.10671-1.115625-.87315-1.5375117-1.48438-1.55628h-.07763c-.75673 0-1.60086.55315-1.76578 1.50003-.26197 1.49998-1.05748 3.83435-3.4442 5.00622-.19405.10317-.3881.18751-.60152.26253-.54331.20622-1.16425.34687-1.853117.42189-.252202.02809-.436543.23437-.436543.46875v4.89368c0 .2438.193993.45.446255.4688.572445.0469 1.183675.2906 1.823985.5812l.65002.3188h.00971c1.09633.525 2.23151 1.0781 3.47334 1.0781h2.64863c1.70756 0 2.49346-.075 2.96886-.5437.4268-.4219.7858-1.1156.5918-1.6876.4657-.2812.7665-.7593.8538-1.2562.0582-.3468.0097-.675-.1261-.9375.4365-.3187.6597-.84375.6597-1.35936zm-11.74913 3.35626c-.42689-.1875-.85378-.3375-1.280669-.4219v-4.07808c.455969-.06563.892569-.1688 1.280669-.29062zm10.12883-2.64375c-.2522 0-.4559.17815-.4851.42185-.0291.2344.1359.4594.3784.5063.2911.0562.2523.4031.2329.5062-.0485.2531-.2814.7125-.8441.7219-.0582-.0094-.1358-.0094-.2134-.0094-.2523 0-.4657.1969-.4851.4407-.0097.2437.1746.4593.4269.4968.0776 0 .1455.0094.2134.0094.0485.0094.0776.0281.0873.0281.0388.075-.0485.45-.359.75-.2522.2438-1.2612.2625-2.27996.2625h-2.64863c-1.0187 0-2.06654-.5062-3.08524-.9937-.02913-.0188-.06792-.0281-.09699-.0469v-5.62501c2.94939-1.39687 3.82253-4.33125 4.0748-5.7375.07763-.46875.49481-.72189.84407-.72189.42689.018768.53365.45004.55302.71251.19405 1.97811-.46568 3.90003-.47539 3.91874-.0485.14065-.01942.30001.06792.42189.097.12188.24255.19689.39776.19689h3.27924c.2426 0 .4269.1125.5628.32811.194.31877.1746.73127.0582.92811-.0582.10317-.1261.12188-.2038.12188-.2716 0-.4851.21567-.4851.46875 0 .26253.2135.46875.4851.46875.6307 0 .6501.63749.6501.71251s-.0194.71251-.6501.71251z"
            fill="#7b8385"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterColors: {
      type: Object,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    avatarPath: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    photoPath: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpp {
  &__container {
    padding: 13px;

    & + & {
      margin-top: -13px;
    }
  }

  &__header {
    display: flex;
  }

  &__header-left {
    display: flex;

    flex-grow: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 7px;
  }

  &__author-name {
    font-size: 12px;
    font-weight: bold;
  }

  &__post-info {
    display: flex;
  }

  &__date {
    font-weight: 300;
    font-size: 9px;
  }

  &__group-name {
    margin-left: 3px;
    font-size: 9px;
  }

  &__option-dots {
    display: flex;
    flex-direction: column;
  }

  &__option-dot {
    height: 3px;
    width: 3px;

    border-radius: 50%;

    & + & {
      margin-top: 1px;
    }
  }

  &__text-content {
    font-size: 12px;

    margin-top: 9px;
  }

  &__photo {
    width: calc(100% + 26px);
    height: 136px;

    margin: 12px -13px 0 -13px;
  }

  &__actions {
    padding-top: 9px;
    padding-bottom: 10px;

    display: flex;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;

    & + & {
      margin-left: 28px;

      &--right {
        margin-left: auto;
      }
    }

    &--right {
      margin-left: auto;
    }
  }

  &__action-text {
    font-size: 9px;
    font-weight: bold;

    margin-left: 4px;
    margin-right: 4px;
  }
}
</style>
