<template>
  <div v-if="!isPublicCredentialSupported">Your browser doesn't support passkeys.</div>
  <div v-else-if="credentials">
    <spinner-overlay v-if="isBusy" />

    <div
      v-for="credential in credentials"
      :key="credential.id"
      class="row mb-2 webauthn_credential"
      :id="`webauthn_credential_${credential.id}`"
    >
      <div class="col">
        <b>{{ credential.name }}</b> -
        <small class="text-muted">registered on <datetime-formatter :timestamp="credential.created_at" /></small>
      </div>
      <div class="col-auto">
        <ion-icon name="trash-outline" class="text-danger" @click="handleRemoveCredentialClick(credential)" />
      </div>
    </div>
    <new-passkey-form @change="handleCredentialsChanged" />
  </div>
  <disciple-spinner v-else />
</template>

<script>
import DatetimeFormatter from "app_manager/components/datetime-formatter.vue";
import NewPasskeyForm from "app_manager/components/mfa/new-passkey-form.vue";
import DiscipleSpinner from "app_manager/components/disciple-spinner.vue";
import SpinnerOverlay from "app_manager/components/spinner-overlay.vue";

export default {
  components: {
    DatetimeFormatter,
    NewPasskeyForm,
    DiscipleSpinner,
    SpinnerOverlay,
  },
  data() {
    return {
      isBusy: false,
      isPublicCredentialSupported: null,
      credentials: null,
    };
  },
  created() {
    this.isPublicCredentialSupported = typeof PublicKeyCredential !== "undefined";
    this.loadCredentials();
  },
  methods: {
    async loadCredentials() {
      try {
        const response = await $.get("/api/webauthn_credentials");
        this.credentials = response.webauthn_credentials;
      } catch {
        this.$bvToast.toast("An error occured while loading passkeys.", {
          title: "Error",
          variant: "danger",
        });
      }
    },
    async removeCredential(id) {
      await $.ajax({
        url: `/api/webauthn_credentials/${id}`,
        method: "DELETE",
      });
      await this.loadCredentials();
    },
    async handleCredentialsChanged() {
      this.isBusy = true;

      try {
        await this.loadCredentials();
      } finally {
        this.isBusy = false;
      }
    },
    async handleRemoveCredentialClick(credential) {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete the ${credential.name} passkey?`,
        { title: "Delete passkey", centered: true },
      );
      if (!confirm) {
        return;
      }

      this.isBusy = true;
      try {
        await this.removeCredential(credential.id);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ion-icon {
  cursor: pointer;
}
</style>
