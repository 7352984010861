<template>
  <b-modal
    ref="modal"
    header-bg-variant="primary"
    :centered="true"
    cancel-variant="light"
    title-tag="h3"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
  </b-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "visible",
    event: "change",
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    hide(trigger) {
      this.$refs.modal.hide(trigger);
    },
  },
};
</script>
<style lang="scss">
.modal {
  color: #0f545c !important;

  &-title {
    color: #0f545c !important;
  }
  &-header {
    background-color: #deedeb !important;
    border-bottom: none;
  }

  &-body,
  &-footer {
    border-top: 1px solid rgba(15, 84, 92, 0.2);
  }
}
</style>
