<template>
  <div class="liquid-playground mt-6">
    <h1>Liquid playground</h1>

    <h2>Platform</h2>
    <div class="form-check">
      <input id="android" v-model="platform" class="form-check-input" type="radio" name="android" value="android" />
      <label class="form-check-label" for="android">Android</label>
    </div>
    <div class="form-check">
      <input id="ios" v-model="platform" class="form-check-input" type="radio" name="ios" value="ios" />
      <label class="form-check-label" for="ios">iOS</label>
    </div>

    <h2 class="mt-2">Input</h2>
    <b-form-textarea v-model="input" :rows="20" />

    <h2 class="mt-2">Result</h2>
    <code class="result">{{ result }}</code>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      platform: null,
      input: "",
      result: "",
      rendererUrl: null,
    };
  },
  watch: {
    platform() {
      this.renderTemplate();
    },
    input() {
      this.renderTemplate();
    },
  },
  beforeMount() {
    this.rendererUrl = this.$el.dataset.rendererUrl;
  },
  methods: {
    renderTemplate: _.debounce(async function () {
      const response = await $.ajax({
        method: "POST",
        url: this.rendererUrl,
        data: { template: this.input, platform: this.platform },
      });
      this.result = response.result;
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.result {
  white-space: pre-wrap;
}
</style>
