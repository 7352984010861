<template>
  <div>
    <b-form-input
      :id="id"
      v-model="model"
      :state="state"
      :style="{ 'background-color': backgroundColor, color: textColor }"
    />
    <color-picker-popover v-model="model" :target="id" @show="handleShowPopover" />
  </div>
</template>

<script>
import ColorPickerPopover from "./color-picker-popover";
import { luminance } from "utils";

export default {
  components: {
    ColorPickerPopover,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    backgroundColor() {
      return this.value;
    },
    textColor() {
      return luminance(this.value) > 127 ? "black" : "white";
    },
  },
  methods: {
    handleShowPopover() {
      this.$emit("show-popover");
    },
  },
};
</script>
