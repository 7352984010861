<template>
  <div v-if="!isDone && !isLoading" class="disciple-card">
    <spinner-overlay v-if="isBusy" />

    <h4>iOS – additional information needed</h4>

    <p>
      In order to improve submissions to the App Store, we need some extra information to help us submit quicker and
      more reliably.
    </p>
    <p>
      In
      <a href="https://appstoreconnect.apple.com/" target="_blank"> App Store Connect </a>, go to the Users and Access
      page and create an App Store Connect API key. Give it the name <i>Disciple Media</i> and grant it Admin access.
      Make a note of the Issuer ID and the Key ID, then download the key. Enter all of these here:
    </p>

    <div class="row mb-4">
      <div class="col-8">
        <div class="row mb-2">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-issuer-id" class="mb-0">Issuer ID</label>
          </div>
          <div class="col-8">
            <b-form-input
              id="asc-api-issuer-id"
              v-model="$v.ascApiIssuerId.$model"
              type="text"
              :state="$v.ascApiIssuerId | dirtyValidation"
            />
            <b-form-invalid-feedback v-if="!$v.ascApiIssuerId.required"
              >This field is required.
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-key-id" class="mb-0">Key ID</label>
          </div>
          <div class="col-8">
            <b-form-input
              id="asc-api-key-id"
              v-model="$v.ascApiKeyId.$model"
              type="text"
              :state="$v.ascApiKeyId | dirtyValidation"
            />
            <b-form-invalid-feedback v-if="!$v.ascApiKeyId.required">This field is required. </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-key" class="mb-0">Key</label>
          </div>
          <div class="col-8">
            <file-text-input v-model="$v.ascApiKey.$model" label-id="asc-api-key" />
            <b-form-invalid-feedback :force-show="$v.ascApiKey.$dirty && !$v.ascApiKey.required"
              >This field is required.
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex">
        <button class="btn btn-primary" @click="handleConnect">Connect</button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import SpinnerOverlay from "app_manager/components/spinner-overlay";
import FileTextInput from "app_manager/components/file-text-input";

export default {
  components: {
    SpinnerOverlay,
    FileTextInput,
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: true,
      isBusy: false,
      isDone: false,

      clientId: null,

      ascApiIssuerId: null,
      ascApiKeyId: null,
      ascApiKey: null,
    };
  },
  beforeMount() {
    this.clientId = this.$el.dataset.clientId;
  },
  async mounted() {
    await this.fetchState();
    this.isLoading = false;
  },
  methods: {
    async fetchState() {
      const response = await $.ajax({
        method: "GET",
        url: `/api/clients/${this.clientId}/app_store_settings`,
      });

      this.ascApiIssuerId = response.asc_api_issuer_id;
      this.ascApiKeyId = response.asc_api_key_id;
      this.ascApiKey = response.asc_api_key;
    },
    async handleConnect() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isBusy = true;

      try {
        const response = await $.ajax({
          method: "POST",
          url: `/api/clients/${this.clientId}/app_store_settings/update_asc_credentials`,
          contentType: "application/json",
          data: JSON.stringify({
            asc_api_issuer_id: this.ascApiIssuerId,
            asc_api_key_id: this.ascApiKeyId,
            asc_api_key: this.ascApiKey,
          }),
        });

        if (response.errors) {
          response.errors.forEach((error) => {
            const message = this.$createElement("span", {
              domProps: { innerHTML: error },
            });
            this.$bvToast.toast(message, {
              variant: "danger",
              title: "Error",
            });
          });
        } else {
          this.isDone = true;

          this.$bvToast.toast("The App Store Connect keys have been successfully saved.", {
            variant: "success",
            title: "Saved",
          });
        }
      } finally {
        this.isBusy = false;
      }
    },
  },
  validations: {
    ascApiIssuerId: {
      required,
    },
    ascApiKeyId: {
      required,
    },
    ascApiKey: {
      required,
    },
  },
};
</script>
