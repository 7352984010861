<template>
  <spinner v-bind="$attrs" line-fg-color="#00857E" v-on="$listeners" />
</template>

<script>
// https://github.com/dzwillia/vue-simple-spinner/issues/9#issuecomment-368727436
import Spinner from "vue-simple-spinner/src/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  inheritAttrs: false,
};
</script>
