<template>
  <div class="cppb__circle" :style="{ 'background-color': masterColors.post_tint }">
    <svg fill="none" height="20" viewBox="0 0 19 20" width="19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m19 9.88989c0 .35621-.2375.59371-.5938.59371h-8.3124v8.3125c0 .3563-.23755.5938-.5938.5938s-.59375-.2375-.59375-.5938v-8.3125h-8.3125c-.35625 0-.59375-.2375-.59375-.59371 0-.35625.2375-.59375.59375-.59375h8.3125v-8.312497c0-.35625.2375-.59375.59375-.59375s.5938.2375.5938.59375v8.312497h8.3124c.3563 0 .5938.2375.5938.59375z"
        :fill="masterColors.post_background"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cppb {
  &__circle {
    position: absolute;
    right: 13px;
    bottom: 50px;

    width: 43px;
    height: 43px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 2px 2px rgba(0, 0, 0, 0.05);
  }
}
</style>
