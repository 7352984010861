import Vue from "vue";
import autosize from "autosize";

/* global iFrameResize */
import "iframe-resizer";

Vue.directive("autosize-iframe", {
  inserted(el, { value = {} }) {
    iFrameResize(value, el);
  },
});

Vue.directive("autosize-textarea", {
  inserted(el) {
    autosize(el);
  },
});
