<template>
  <div class="spinner-overlay">
    <div class="spinner-overlay__backdrop" />
    <div class="spinner-overlay__content">
      <disciple-spinner :size="spinnerSize" />
    </div>
  </div>
</template>

<script>
import DiscipleSpinner from "app_manager/components/disciple-spinner";

export default {
  components: {
    DiscipleSpinner,
  },
  props: {
    spinnerSize: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-overlay {
  &__backdrop {
    background-color: black;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2010;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
