<template>
  <div class="cpub__container">
    <div class="cpub__url">
      <span v-if="communityUrl">{{ communityUrl }}</span>
      <span v-else>&nbsp;</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    communityUrl: {
      type: String,
      default: null,
    },
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpub {
  &__container {
    position: relative;
    padding: 5px 24px 4px 25px;
    border-bottom: 1px solid rgba(87, 87, 87, 0.1);

    background: #f5f5f5;
  }

  &__url {
    background: #e3e7e8;
    border-radius: 31px;

    padding: 3px 9px 2px 9px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 10px;
    font-weight: bold;
    color: #505e64;
    line-height: 14px;
  }
}
</style>
