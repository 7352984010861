<template>
  <button v-if="value" type="button" class="btn btn-secondary btn-sm" @click="handleRemove">Remove</button>
  <input v-else :id="labelId" type="file" @change="handleChange" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    labelId: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleChange(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.$emit("input", event.target.result);
      };
      reader.readAsText(file);
    },
    handleRemove() {
      this.$emit("input", null);
    },
  },
};
</script>
