<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    top: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height: null,
    };
  },
  computed: {
    styles() {
      return {
        height: `${this.height}px`,
      };
    },
  },
  mounted() {
    window.addEventListener("resize", () => this.autosize());
    this.autosize();
  },
  methods: {
    autosize() {
      this.height = window.innerHeight - this.top;
    },
  },
};
</script>
