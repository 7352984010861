<template>
  <span>{{ formattedTimestamp }}</span>
</template>

<script>
export default {
  props: {
    timestamp: {
      type: [Date, String],
      required: true,
    },
  },
  computed: {
    formattedTimestamp() {
      const date = new Date(this.timestamp);
      return date.toLocaleString();
    },
  },
};
</script>
