<template>
  <div>
    <h1 class="font-weight-bold text-center">Verify your device</h1>

    <p>Click verify and provide your passkey.</p>

    <div v-if="options.available_methods.includes('email')" class="form-row">
      <div class="col-auto ml-auto">
        <a href="#" class="login-form__link" @click="handleSwitchToEmail">Verify with email</a>
      </div>
    </div>

    <div class="form-row my-5">
      <div class="col-auto mx-auto">
        <button class="btn btn-primary login-form__button" @click="handleVerify">Verify</button>
      </div>
    </div>
  </div>
</template>

<script>
import { get, parseRequestOptionsFromJSON } from "@github/webauthn-json/browser-ponyfill";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.handleVerify();
  },
  methods: {
    async handleVerify() {
      const newVerificationResponse = await $.ajax({
        method: "POST",
        url: "/users/webauthn_verification.json",
      });
      if (newVerificationResponse.result === "redirect") {
        window.location = newVerificationResponse.next;
        return;
      }
      const requestOptions = parseRequestOptionsFromJSON({ publicKey: newVerificationResponse.options });
      const requestResponse = await get(requestOptions);

      const verificationResponse = await $.ajax({
        method: "POST",
        url: "/users/webauthn_verification/verify.json",
        data: JSON.stringify({ credential: requestResponse }),
        contentType: "application/json",
      });

      if (verificationResponse.result === "redirect") {
        window.location = verificationResponse.next;
      } else if (verificationResponse.result === "invalid_challenge") {
        alert("Invalid credential");
      }
    },
    handleSwitchToEmail() {
      this.$emit("switch-to-email");
    },
  },
};
</script>
