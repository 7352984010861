<template>
  <div v-if="!isLoading" class="disciple-card apple-account-editor__card">
    <spinner-overlay v-if="isBusy" />

    <div class="row">
      <div class="col ml-auto mb-6">
        <p>
          <template v-if="features.enterprise_distribution">
            You’ll be hosting the app under your Apple Enterprise account. So first of all, you need to
            <a href="https://developer.apple.com/programs/enroll/" target="_blank" rel="noopener noreferrer">create</a>
            an “Enterprise” Apple developer account if you don’t have one already.
          </template>
          <template v-else>
            You’ll be hosting the app under your Apple developer account. So first of all, you need to
            <a href="https://developer.apple.com/programs/enroll/" target="_blank" rel="noopener noreferrer">create</a>
            a “Organization” Apple developer account if you don’t have one already.
          </template>
        </p>
        <p>
          <template v-if="features.enterprise_distribution">
            In order to help you distribute your iOS app to your community members, we need a few things from you first.
            Please follow the instructions below.
          </template>
          <template v-else>
            In order to help you push your app to the App Store, we need a few things from you first. Please follow the
            instructions below.
          </template>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-11 d-flex align-items-center">
        <span>INVITE US TO YOUR APPLE DEVELOPER ACCOUNT</span>
      </div>
      <div class="col-1 d-flex justify-content-end align-items-center">
        <a
          class="no-decoration"
          target="_blank"
          rel="noopener noreferrer"
          href="https://resources.disciplemedia.com/how-do-i-link-my-developer-account-with-disciple"
        >
          <i class="icon-question apple-account-editor__heading-icon" />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr class="apple-account-editor__section-divider mt-2" />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <div class="form-check">
          <input id="org-account-verified" v-model="isOrgAccountVerified" class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="org-account-verified">
            <template v-if="features.enterprise_distribution">
              I confirm my account type on developer.apple.com has the “Enterprise” type, not the “organization” or the
              “individual” one.
            </template>
            <template v-else>
              I confirm my account type on developer.apple.com has the “Organization” type, not the “individual” one.
            </template>
          </label>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <span>
          <template v-if="features.enterprise_distribution">
            In
            <a href="https://developer.apple.com" target="_blank" rel="noopener noreferrer">Apple Developer</a>
            , login to your account, then go to People and click on Manage Users. Then invite the email
            appstores@disciplemedia.com as an Admin on your account.
          </template>
          <template v-else>
            In
            <a href="https://appstoreconnect.apple.com" target="_blank" rel="noopener noreferrer">App Store Connect</a>
            , go to the Users and Access page, and invite the email appstores@disciplemedia.com as an Admin on your
            account.
          </template>
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <div class="form-check">
          <input id="dev-invited" v-model="isDevInvited" class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="dev-invited">
            I confirm I have invited appstores@disciplemedia.com as an Admin.
          </label>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <span>
          Also on the Users and Access page, go to Keys and create an App Store Connect API key. Give it the name
          <i>Disciple Media</i> and grant it Admin access. Make a note of the Issuer ID and the Key ID, then download
          the key. Enter all of these here:
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-8">
        <div class="row mb-2">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-issuer-id" class="mb-0">Issuer ID</label>
          </div>
          <div class="col-8">
            <b-form-input id="asc-api-issuer-id" v-model="ascApiIssuerId" type="text" />
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-key-id" class="mb-0">Key ID</label>
          </div>
          <div class="col-8">
            <b-form-input id="asc-api-key-id" v-model="ascApiKeyId" type="text" />
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <label for="asc-api-key" class="mb-0">Key</label>
          </div>
          <div class="col-8">
            <file-text-input v-model="ascApiKey" label-id="asc-api-key" />
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <span>
          In your
          <a href="https://developer.apple.com/account/" target="_blank" rel="noopener noreferrer"
            >Apple Developer Console</a
          >
          go to the
          <a href="https://developer.apple.com/account/#/membership/" target="_blank" rel="noopener noreferrer"
            >membership</a
          >
          page, and enter the Team ID below.
        </span>
      </div>
    </div>
    <div class="row mb-6">
      <div class="col-8">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <label for="team-id" class="mb-0">Team ID</label>
          </div>
          <div class="col-8">
            <b-form-input id="team-id" v-model="teamId" type="text" placeholder='e.g. "654APT3LQK"' />
          </div>
        </div>
      </div>
    </div>

    <div class="row apple-account-editor__done-row">
      <div class="col d-flex justify-content-center">
        <button :disabled="!isDoneAvailable" class="btn btn-primary" @click="onDoneClick">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import SpinnerOverlay from "app_manager/components/spinner-overlay.vue";
import FileTextInput from "app_manager/components/file-text-input";

export default {
  components: {
    SpinnerOverlay,
    FileTextInput,
  },
  data() {
    return {
      clientId: null,
      features: null,

      isLoading: true,
      isSaving: false,
      isBusy: false,

      isOrgAccountVerified: false,
      isDevInvited: false,
      teamId: null,
      ascApiIssuerId: null,
      ascApiKeyId: null,
      ascApiKey: null,
    };
  },
  computed: {
    isDoneAvailable() {
      return (
        !this.isSaving &&
        this.isOrgAccountVerified &&
        this.isDevInvited &&
        Boolean(_.trim(this.teamId)) &&
        Boolean(_.trim(this.ascApiIssuerId)) &&
        Boolean(_.trim(this.ascApiKeyId)) &&
        Boolean(_.trim(this.ascApiKey))
      );
    },
  },
  watch: {
    isOrgAccountVerified() {
      this.updateState();
    },
    isDevInvited() {
      this.updateState();
    },
    teamId() {
      this.updateState();
    },
    ascApiIssuerId() {
      this.updateState();
    },
    ascApiKeyId() {
      this.updateState();
    },
    ascApiKey() {
      this.updateState();
    },
  },
  beforeMount() {
    this.clientId = this.$el.dataset.clientId;
    this.features = JSON.parse(this.$el.dataset.features);
  },
  mounted() {
    this.fetchState().then(() => (this.isLoading = false));
  },
  methods: {
    async fetchState() {
      const response = await $.ajax({
        method: "GET",
        url: `/api/clients/${this.clientId}/app_store_settings`,
      });

      this.isOrgAccountVerified = response.org_account_verified;
      this.isDevInvited = response.dev_invited;
      this.teamId = response.team_id;
      this.ascApiIssuerId = response.asc_api_issuer_id;
      this.ascApiKeyId = response.asc_api_key_id;
      this.ascApiKey = response.asc_api_key;
    },
    updateState() {
      if (this.isLoading) {
        return;
      }

      this.isSaving = true;
      this.saveState(this);
    },
    saveState: _.debounce(async function (vm) {
      await $.ajax({
        method: "PATCH",
        url: `/api/clients/${vm.clientId}/app_store_settings`,
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify({
          settings: {
            org_account_verified: vm.isOrgAccountVerified,
            dev_invited: vm.isDevInvited,
            team_id: vm.teamId,
            asc_api_issuer_id: vm.ascApiIssuerId,
            asc_api_key_id: vm.ascApiKeyId,
            asc_api_key: vm.ascApiKey,
          },
        }),
      });
      vm.isSaving = false;
    }, 500),
    async onDoneClick() {
      this.isSaving = true;
      this.isBusy = true;

      try {
        const response = await $.ajax({
          method: "POST",
          url: `/api/clients/${this.clientId}/app_store_settings/complete`,
        });

        if (response.errors) {
          response.errors.forEach((error) => {
            const message = this.$createElement("span", {
              domProps: { innerHTML: error },
            });
            this.$bvToast.toast(message, {
              variant: "danger",
              title: "Error",
            });
          });
        } else {
          window.location = response.next;
        }
      } finally {
        this.isSaving = false;
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss">
.apple-account-editor {
  &__card {
    padding: 4rem 6rem;
  }

  &__heading-icon {
    font-size: 1.25rem;
  }

  &__section-divider {
    height: 1px;
    width: 100%;
  }

  &__done-row {
    margin-top: 6rem;
  }
}
</style>
