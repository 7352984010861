<template>
  <div>
    <div class="d-flex flex-column">
      <div
        class="color-scheme__thumbnail"
        :style="{ 'background-color': scheme.primaryColor }"
        @click="selected = scheme"
      >
        <div class="color-scheme__header">
          <div class="color-scheme__left-header-icon" :style="{ 'border-color': iconBorderColor }" />
          <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
            <path
              clip-rule="evenodd"
              d="m7.4534 13.7452c-3.22667 0-5.85333-2.6267-5.85333-5.85333 0-3.22666 2.62666-5.85333 5.85333-5.85333 3.2267 0 5.8533 2.62667 5.8533 5.85333 0 1.33334-.44 2.56003-1.1866 3.53333-.3334.4267-.7067.8-1.1334 1.1333-.9733.7467-2.19997 1.1867-3.5333 1.1867zm8.2799 1.2933-2.48-2.48c1.028-1.28 1.6534-2.89336 1.6534-4.66669 0-4.12-3.3454-7.453333-7.45337-7.453333-4.10666 0-7.45333 3.333333-7.45333 7.453333 0 4.10669 3.34667 7.43999 7.45333 7.43999 1.76 0 3.38797-.6133 4.66667-1.64l2.48 2.48c.16.16.36.24.56.24.2133 0 .4133-.08.5733-.24.3067-.3067.3067-.8133 0-1.1333z"
              :fill="iconBorderColor"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div class="color-scheme__outer-content" :style="{ 'border-color': outerContentBorderColor }">
          <div class="color-scheme__inner-content" :style="{ 'background-color': scheme.actionColor }" />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <input :id="'color-scheme-' + _uid" v-model="selected" :value="scheme" type="radio" />
        <label :for="'color-scheme-' + _uid">{{ scheme.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    scheme: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    iconBorderColor() {
      return this.scheme.theme === "light" ? "#E5E5E5" : "rgba(255, 255, 255, 0.4)";
    },
    outerContentBorderColor() {
      return this.scheme.theme === "light" ? "#E5E5E5" : "#414141";
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin: 1px 0 0 6px;
  font-size: 12px;
}

.color-scheme {
  &__thumbnail {
    padding: 7px 7px 0 7px;

    border-radius: 5px;

    border: 1px solid #e5e5e5;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__left-header-icon {
    width: 16px;
    height: 16px;

    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }

  &__outer-content {
    padding: 12px;
    margin-top: 12px;

    border: 1px solid #e5e5e5;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }

  &__inner-content {
    height: 18px;

    border-radius: 5px;
  }
}
</style>
