<template>
  <div class="row mt-3">
    <div class="col">
      <b-form-group>
        <b-form-input v-model="$v.name.$model" type="text" placeholder="Enter a name for your new passkey" />
      </b-form-group>
    </div>
    <div class="col-auto">
      <b-button variant="primary" @click="handleAddNewCredential" :disabled="$v.$invalid">Add</b-button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { create, parseCreationOptionsFromJSON } from "@github/webauthn-json/browser-ponyfill";

export default {
  mixins: [validationMixin],
  data() {
    return {
      name: "",
    };
  },
  methods: {
    async handleAddNewCredential() {
      const creationOptionsJson = await $.get("/api/webauthn_credentials/new");
      const creationOptions = parseCreationOptionsFromJSON({ publicKey: creationOptionsJson });
      const createResponse = await create(creationOptions);

      await $.ajax({
        url: "/api/webauthn_credentials",
        method: "POST",
        data: JSON.stringify({
          name: this.name,
          credential: createResponse,
        }),
        contentType: "application/json",
      });

      this.name = "";
      this.$emit("change");
    },
  },
  validations: {
    name: {
      required,
    },
  },
};
</script>
