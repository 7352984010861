<template>
  <div class="cpaf__container">
    <p class="cpaf__heading" :style="{ color: masterColors.wall_text }">Activity feed</p>

    <post
      v-bind="$props"
      user-name="Jenny Pearson"
      date="7 mins ago"
      avatar-path="./assets/post_avatar_1.png"
      group-name="The music room"
      photo-path="./assets/post_photo_1.jpg"
    >
      Hi guys! Check out this new furniture place that just popped up downtown
      <span style="font-weight: bold">#swag</span>
    </post>
    <post
      v-bind="$props"
      user-name="Francis Williams"
      date="23 mins ago"
      avatar-path="./assets/post_avatar_2.png"
      group-name="The music room"
    >
      Just bumped into these two legends while I was out and about looking for a new pair of sneakers
    </post>
  </div>
</template>

<script>
import Post from "./post";

export default {
  components: {
    Post,
  },
  props: {
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpaf {
  &__container {
    margin-top: 12px;
  }

  &__heading {
    padding-left: 12px;
    margin-bottom: 5px;

    font-weight: bold;
    font-size: 12px;
  }
}
</style>
