<template>
  <div class="col-12 col-md d-flex flex-column align-items-center cpc__column">
    <h4 class="mt-5 cpc__heading">Community preview</h4>
    <p class="cpc__text">The social content shown below is an example.</p>
    <community-preview v-if="masterColors" v-bind="$props" />
  </div>
</template>

<script>
import CommunityPreview from "app_manager/components/community-preview";

export default {
  components: {
    CommunityPreview,
  },
  props: {
    communityUrl: {
      type: String,
      default: "",
    },
    logoUrl: {
      type: String,
      default: null,
    },
    masterColors: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpc__column {
  margin-bottom: 64px;
}
</style>
