<template>
  <b-sidebar v-model="navigationLinksDisplayed" no-header class="px-2" width="190px">
    <navigation-links :menu-items="menuItems" />
  </b-sidebar>
</template>

<script>
import NavigationLinks from "app_manager/components/navigation/navigation-links";

export default {
  components: {
    NavigationLinks,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
      navigationLinksDisplayed: true,
    };
  },
  watch: {
    navigationLinksDisplayed(val) {
      const sidebarHiddenClass = "sidebar-hidden";
      const sidebarHiddenClassPresent = document.body.classList.contains(sidebarHiddenClass);

      if (val && sidebarHiddenClassPresent) {
        document.body.classList.remove(sidebarHiddenClass);
      } else if (!val && !sidebarHiddenClassPresent) {
        document.body.classList.add(sidebarHiddenClass);
      }
    },
  },
  created() {
    this.$bus.$on("activate-nav-category", this.onNavCategoryActivated);
  },
  destroyed() {
    this.$bus.$off("activate-nav-category", this.onNavCategoryActivated);
  },
  methods: {
    onNavCategoryActivated(category) {
      if (!category) {
        this.navigationLinksDisplayed = false;
      } else if (this.activeCategory?.label === category?.label) {
        this.navigationLinksDisplayed = !this.navigationLinksDisplayed;
      } else {
        this.navigationLinksDisplayed = true;
      }

      this.activeCategory = category;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  margin-top: var(--top-bar-height);
  overflow-y: auto;
  min-width: 206px;
  max-width: 206px;
  top: 64px !important;
  left: 88px !important;
}
</style>
