<template>
  <div class="d-flex flex-column category-bar">
    <nav class="drawer-container d-flex justify-content-start align-items-center flex-column pt-2">
      <b-btn class="mr-0 p-0 nav-category-toggle mb-2" @click="activateActiveOrFirstCategory">
        <div class="img-wrapper d-flex align-items-center justify-content-center">
          <img src="./assets/three-lines.svg" width="20" height="20" />
        </div>
      </b-btn>

      <div class="scrollable-wrapper">
        <navigation-categories class="navigation-categories-desktop" :menu-items="menuItems" />
      </div>
    </nav>
  </div>
</template>

<script>
import NavigationCategories from "app_manager/components/navigation/navigation-categories";

export default {
  components: {
    NavigationCategories,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
      navLinksDisplayed: false,
    };
  },
  created() {
    this.$bus.$on("activate-nav-category", this.onActivateNavCategory);
  },
  destroyed() {
    this.$bus.$off("activate-nav-category", this.onActivateNavCategory);
  },
  methods: {
    onActivateNavCategory(category) {
      this.activeCategory = category;
    },
    activateActiveOrFirstCategory() {
      this.$bus.$emit("activate-nav-category", this.activeCategory || this.menuItems[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 88px;
  z-index: 1039;
  background-color: var(--color-ash);
  border-right: 1px solid var(--color-light-gray);
}

.nav-category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px !important;
  width: 56px !important;
  border: 0;
  border-radius: 50%;
  background-color: var(--color-ash) !important;

  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 2px 2px rgba(0, 0, 0, 0.05);
  }
}

.scrollable-wrapper {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100%;
}

.img-wrapper {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
}
</style>
