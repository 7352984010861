<template lang="slm">
  .disciple-card.asset-card
    .row
      .col-10
        .row
          .col
            .disciple-card__header
              slot name="header"
              .icon-question @click="$refs.assetExampleModal.show()" v-if="$slots.assetExample"
            div
              slot name="description"
              p Required: {{assetFormat}}, {{assetTransparentText ? assetTransparentText + "," : ""}} {{assetDimensionsPrefix}} {{assetWidth}}x{{assetHeight}}

        .row
          .col
            button.btn.btn-primary.file-button @click="openFileSelect()" Browse
            button.btn.btn-light.ml-2 v-if="assetPath !== null" @click="$refs.removeAssetModal.show()" Remove
      .col-2.d-flex.justify-content-center
        .asset-placeholder ref="asset-placeholder" v-if="assetPath === null"
          .font-weight-light.asset-placeholder-text.asset-placeholder-text--horizontal-only {{assetTransparentText}} {{assetFormat}}
          i.icon-cloud-upload.asset-placeholder-icon
          .font-weight-light.asset-placeholder-text {{assetWidth}} x {{assetHeight}}
        div v-else=""
          img.asset-preview :class="{ 'asset-preview--rounded': rounded }":src="previewUrl"
    custom-b-modal[
      ref="assetExampleModal"
      :title="assetExampleModalHeaderText"
      ok-title="Got it"
      ok-only
      size="lg"
    ]
      .asset-card__example-image
        slot name="assetExample"
    custom-b-modal[
      ref="removeAssetModal"
      title="Remove this asset?"
      ok-title="Confirm"
      ok-variant="danger"
      no-fade
      @ok="removeAsset"
    ]
</template>

<script>
/* global cloudinary */

import { getChildrenTextContent } from "utils";

export default {
  model: {
    prop: "assetPath",
  },
  props: {
    assetWidth: {
      type: Number,
      required: true,
    },
    assetHeight: {
      type: Number,
      required: true,
    },
    assetDimensionsPrefix: {
      type: String,
      default: null,
    },
    assetFormat: {
      type: String,
      required: true,
    },
    assetTransparent: {
      type: Boolean,
      default: null,
    },
    assetPath: {
      type: String,
      required: false,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadWidget: cloudinary.createUploadWidget(this.buildUploadWidgetOptions(), this.handleUploadWidgetEvent),
    };
  },
  computed: {
    uploadWidgetOptions() {
      return this.buildUploadWidgetOptions();
    },
    assetTransparentText() {
      if (this.assetTransparent == null) {
        return "";
      }

      return this.assetTransparent ? "transparent" : "not transparent";
    },
    aspectRatio() {
      return this.assetWidth / this.assetHeight;
    },
    previewUrl() {
      return $.cloudinary.url(this.assetPath);
    },
    assetExampleModalHeaderText() {
      return `Asset example for ${getChildrenTextContent(this.$slots.header)}`;
    },
  },
  watch: {
    buildUploadWidgetOptions(newVal) {
      this.uploadWidget.update(newVal);
    },
  },
  mounted() {
    this.sizeAssetPlaceholder();
    this.$bus.$on("resize", () =>
      // eslint-disable-next-line vue/valid-next-tick
      this.$nextTick(() => this.sizeAssetPlaceholder()),
    );
  },
  methods: {
    buildUploadWidgetOptions() {
      return {
        uploadPreset: "app_editor",
        sources: ["local", "url"],
        multiple: false,
        resourceType: "image",
        maxFileSize: 20_000_000,
        thumbnails: false,
        showPoweredBy: false,
      };
    },
    handleUploadWidgetEvent(error, result) {
      if (result.event === "success") {
        const uploadedFile = result.info;

        const mpx = (uploadedFile.width * uploadedFile.height) / 1_000_000;
        if (mpx > 25) {
          this.$bvToast.toast(
            `The image is ${mpx.toFixed(
              2,
            )} megapixels which exceeds the maximum allowed resolution of 25 megapixels. Please resize the image and upload again.`,
            {
              variant: "danger",
              title: "Error",
            },
          );
          return;
        }

        this.$emit("input", uploadedFile.path);
      }
    },
    sizeAssetPlaceholder() {
      const $el = $(this.$refs["asset-placeholder"]);

      const height = $el.outerHeight();
      const width = height * this.aspectRatio;

      $el.width(width);

      $el.toggleClass("asset-placeholder--vertical", height > width);
      $el.toggleClass("asset-placeholder--horizontal", height <= width);
    },
    openFileSelect() {
      this.uploadWidget.open();
    },
    removeAsset() {
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss">
.asset-card__example-image img {
  object-fit: contain;
  width: 100%;
}
</style>

<style lang="scss" scoped>
.disciple-card__header {
  align-items: center;
  display: flex;

  .icon-question {
    cursor: pointer;
    margin-left: 0.5rem;
    height: 24px;
  }
}

.asset-card {
  & ::v-deep .modal-body img {
    max-height: 100%;
    max-width: 100%;
  }
}

.asset-placeholder-sizer {
  padding-left: 50%;
  width: 50%;
}

.asset-placeholder {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  * {
    padding: 0.5rem 0.1rem;
  }

  &--vertical {
    .asset-placeholder-text--horizontal-only {
      display: none;
    }
  }
}

.asset-placeholder-text {
  font-size: 0.8rem;
}

.asset-placeholder-icon {
  color: rgba(0, 0, 0, 0.2);
  font-size: 3.125rem;
}

.asset-preview {
  height: 100%;
  object-fit: contain;
  width: 100%;

  &--rounded {
    border-radius: 20px;
  }
}
</style>
