<template>
  <button type="button" class="btn btn-primary" data-dismiss="modal" :disabled="isDisabled" @click="handleClick">
    Invite
  </button>
</template>

<script>
import { validate } from "isemail";

export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    isDisabled() {
      return !validate(this.email);
    },
  },
  mounted() {
    $("#invitation_form_email").on("keyup", () => this.updateState());
  },
  methods: {
    updateState() {
      this.email = $("#invitation_form_email").val();
    },
    handleClick() {
      $("#invitation-modal form").submit();
    },
  },
};
</script>
