<template>
  <div class="presales-onboarding-container">
    <div class="presales-onboarding">
      <div class="tab-content">
        <div id="name" class="tab-pane">
          <top-bar :steps="3" :current-step="1" />
          <div class="presales-onboarding__top-bar-spacer-1" />

          <div class="container">
            <div class="row">
              <div class="col-12 col-md d-flex flex-column justify-content-center">
                <div class="d-none d-md-flex mb-4">
                  <steps :steps="3" :current-step="1" />
                </div>

                <h1>Name your web community</h1>
                <p v-if="isNotFirstCommunity">
                  Since your previous free trial expired over 30 days ago, you’ll need to create a new community.
                </p>

                <b-form-group>
                  <label>Community name*</label>
                  <b-form-input
                    v-model.trim="$v.name.$model"
                    :state="$v.name | dirtyValidation"
                    type="text"
                    class="app-name-input"
                  />
                  <b-form-invalid-feedback v-if="!$v.name.characters">
                    Only letters, numbers, and spaces are supported for community names.
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.name.maxLength">
                    Community name must be shorter than
                    {{ $v.name.$params.maxLength.max }} characters.
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.name.startsWithAlpha">
                    Community names must start with a letter.
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="!$v.name.required">
                    Please enter a community name.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group v-if="generatedCommunityUrl">
                  <label>Community URL:</label>
                  <div class="text-break">{{ generatedCommunityUrl }}</div>
                </b-form-group>

                <b-row no-gutters class="mt-2 mt-md-3">
                  <b-col cols="7" md="6" class="mx-auto mr-md-0">
                    <button
                      type="button"
                      class="btn btn-primary w-100"
                      :disabled="!validateTabChange('#name', '#logo', false)"
                      @click="changeTab('#logo')"
                    >
                      Save
                    </button>
                  </b-col>
                </b-row>
              </div>

              <community-preview-column
                :community-url="generatedCommunityUrl"
                :logo-url="logoUrl"
                :master-colors="masterColors"
              />
            </div>
          </div>
        </div>

        <div id="logo" class="tab-pane">
          <top-bar :steps="3" :current-step="2" />
          <div class="presales-onboarding__top-bar-spacer-1" />

          <div class="container">
            <div class="row">
              <div class="col-12 col-md d-flex flex-column justify-content-center">
                <div class="d-none d-md-flex mb-4">
                  <steps :steps="3" :current-step="2" />
                </div>

                <h1>Upload your community logo</h1>
                <p>This will appear at the top of your community and replace the community name.</p>

                <cloudinary-transmit
                  v-show="!logoPath"
                  v-model="logoPath"
                  :accepted-file-types="['image/jpeg', 'image/png']"
                  upload-preset="app_editor"
                  class="mt-1"
                >
                  <div class="d-flex justify-content-center presales-onboarding__dropzone">
                    <svg
                      fill="none"
                      height="164"
                      viewBox="0 0 164 164"
                      width="164"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <filter
                        id="a"
                        color-interpolation-filters="sRGB"
                        filterUnits="userSpaceOnUse"
                        height="164"
                        width="164"
                        x="0"
                        y="0"
                      >
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                        <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" mode="normal" result="shape" />
                      </filter>
                      <g filter="url(#a)">
                        <circle cx="82" cy="80" fill="#f26b4f" r="80" />
                        <g fill="#fff">
                          <path
                            d="m106.712 61.6113c-2.188-12.8247-12.0997-21.6113-24.712-21.6113-11.1597 0-18.8037 7.0386-21.8286 14.4653-10.4639 1.7041-18.1714 10.1172-18.1714 20.1441 0 11.2427 9.5337 20.3906 21.25 20.3906h8.75c1.3818 0 2.5-1.1182 2.5-2.5s-1.1182-2.5-2.5-2.5h-8.75c-8.96 0-16.25-6.9043-16.25-15.3906 0-7.937 6.5503-14.5435 15.2368-15.3711.9961-.0928 1.8384-.7715 2.1436-1.7237 1.8506-5.7739 7.5781-12.5146 17.6196-12.5146 10.7739 0 18.818 7.6904 20.015 19.1333.127 1.228 1.13 2.1777 2.36 2.2388 5.159.2563 12.625 4.6069 12.625 11.7993 0 6.853-5.52 11.8286-13.125 11.8286h-11.875c-1.3818 0-2.5 1.1182-2.5 2.5s1.1182 2.5 2.5 2.5h11.875c10.332 0 18.125-7.2339 18.125-16.8286 0-8.7402-7.561-15.2271-15.288-16.5601z"
                          />
                          <path
                            d="m73.7673 79.2554 5.7324-5.7257v43.9703c0 1.382 1.1182 2.5 2.5 2.5s2.5-1.118 2.5-2.5c0-12.29 0-31.6804 0-43.9703l5.7324 5.7257c.9766.9717 2.5611.9741 3.5376-.0025.9741-.979.9741-2.561-.0024-3.5376l-10-9.9853c-.4883-.4859-1.128-.73-1.7676-.73-.6397 0-1.2793.2441-1.7676.73l-10 9.9853c-.9765.9766-.9765 2.5586-.0024 3.5376.9765.9766 2.561.9741 3.5376.0025z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </cloudinary-transmit>
                <div v-if="logoUrl" class="d-flex justify-content-center mt-1">
                  <img :src="logoUrl" class="logo-img" />
                </div>

                <b-row class="mt-5">
                  <b-col>
                    <button v-if="logoPath" type="button" class="btn btn-secondary w-100" @click="logoPath = null">
                      Remove
                    </button>
                    <button v-else type="button" class="btn btn-secondary w-100" @click="changeTab('#colours')">
                      Skip
                    </button>
                  </b-col>
                  <b-col>
                    <button v-if="logoPath" type="button" class="btn btn-primary w-100" @click="changeTab('#colours')">
                      Save
                    </button>
                    <button v-else type="button" class="btn btn-primary w-100" disabled>Save</button>
                  </b-col>
                </b-row>
              </div>

              <community-preview-column
                :community-url="generatedCommunityUrl"
                :logo-url="logoUrl"
                :master-colors="masterColors"
              />
            </div>
          </div>
        </div>

        <div id="colours" class="tab-pane">
          <top-bar :steps="3" :current-step="3" />
          <div class="presales-onboarding__top-bar-spacer-1" />

          <div class="container">
            <div class="row">
              <div class="col-12 col-md d-flex flex-column justify-content-center">
                <div class="d-none d-md-flex mb-4">
                  <steps :steps="3" :current-step="3" />
                </div>

                <h1>Choose your theme</h1>

                <div class="row">
                  <color-scheme
                    v-for="scheme in colorSchemes"
                    :key="scheme.name"
                    v-model="selectedColorScheme"
                    :scheme="scheme"
                    class="col-4 mt-3"
                  />
                </div>

                <div v-show="isColorSchemeExpanded" class="mt-5">
                  <div class="row align-items-center">
                    <label for="theme" class="col col-form-label font-weight-bold">Dark theme</label>
                    <div class="col-auto align-items-center ml-auto">
                      <label class="disciple-switch mb-0">
                        <input id="theme" v-model="isDarkTheme" class="disciple-switch__input" type="checkbox" />
                        <span class="disciple-switch__label" />
                        <span class="disciple-switch__handle" />
                      </label>
                    </div>
                  </div>

                  <div class="row align-items-center mt-2">
                    <label for="primary-color" class="col col-form-label font-weight-bold">Background</label>
                    <div class="col-auto ml-auto">
                      <b-form-input
                        id="primary-color"
                        v-model="primaryColor"
                        v-b-modal.primaryColorModal
                        :style="{
                          backgroundColor: primaryColor,
                          color: contrastingTextColor(primaryColor),
                        }"
                        readonly
                      />
                    </div>
                    <color-modal
                      v-model="primaryColor"
                      :background-hex-color="primaryColor"
                      :text-hex-color="secondaryColor"
                      :button-hex-color="actionColor"
                      modal-id="primaryColorModal"
                      color-name="background"
                    />
                  </div>

                  <div class="row align-items-center mt-2">
                    <label for="secondary-color" class="col col-form-label font-weight-bold">Text</label>
                    <div class="col-auto ml-auto">
                      <b-form-input
                        id="secondary-color"
                        v-model="secondaryColor"
                        v-b-modal.secondaryColorModal
                        :style="{
                          backgroundColor: secondaryColor,
                          color: contrastingTextColor(secondaryColor),
                        }"
                      />
                      <color-modal
                        v-model="secondaryColor"
                        :background-hex-color="primaryColor"
                        :text-hex-color="secondaryColor"
                        :button-hex-color="actionColor"
                        modal-id="secondaryColorModal"
                        color-name="text"
                      />
                    </div>
                  </div>

                  <div class="row align-items-center mt-2">
                    <label for="action-color" class="col col-form-label font-weight-bold">Button</label>
                    <div class="col-auto ml-auto">
                      <b-form-input
                        id="action-color"
                        v-model="actionColor"
                        v-b-modal.actionColorModal
                        :style="{
                          backgroundColor: actionColor,
                          color: contrastingTextColor(actionColor),
                        }"
                      />
                    </div>
                    <color-modal
                      v-model="actionColor"
                      :background-hex-color="primaryColor"
                      :text-hex-color="secondaryColor"
                      :button-hex-color="actionColor"
                      modal-id="actionColorModal"
                      color-name="button"
                    />
                  </div>
                </div>

                <b-row class="mt-5">
                  <b-col>
                    <button
                      v-if="!isColorSchemeExpanded"
                      type="button"
                      class="btn btn-secondary w-100"
                      @click.prevent="isColorSchemeExpanded = true"
                    >
                      Create your own
                    </button>
                  </b-col>
                  <b-col>
                    <button type="button" class="btn btn-primary w-100" @click="changeTab('#building')">Save</button>
                  </b-col>
                </b-row>
              </div>

              <community-preview-column
                :community-url="generatedCommunityUrl"
                :logo-url="logoUrl"
                :master-colors="masterColors"
              />
            </div>
          </div>
        </div>

        <div id="building" class="tab-pane">
          <div class="row presales-onboarding__top-bar">
            <div class="col-2 col-md d-flex align-items-center">
              <img
                src="./presales-onboarding/assets/disciple_logo.svg"
                width="144"
                height="32"
                class="ml-3 d-none d-lg-inline"
              />
              <img
                src="./presales-onboarding/assets/disciple_logo_short.svg"
                width="40"
                height="32"
                class="ml-3 d-inline d-lg-none"
              />
            </div>
            <div class="col d-flex justify-content-center align-items-center">
              <building-progress-indicator v-if="currentTab === '#building'" :backend-status="backendStatus" />
            </div>
            <div class="col-3 col-md d-flex justify-content-end align-items-center">
              <template v-if="backendStatus !== 'ready'">
                <span class="presales-onboarding__small-text d-none d-lg-inline"> (Wait for this button) </span>
                <span class="presales-onboarding__arrow ml-2 mr-3 d-none d-lg-inline" />
                <button class="btn mr-4 presales-onboarding__button presales-onboarding__button--disabled" disabled>
                  Go
                </button>
              </template>
              <template v-else>
                <img src="./presales-onboarding/assets/button_frills@2x.png" width="16" height="48" class="mr-1" />
                <a
                  class="btn mr-3 mr-lg-0 d-flex justify-content-center align-items-center presales-onboarding__button presales-onboarding__button--flash"
                  :href="communityUrl"
                  >Go</a
                >
                <img
                  src="./presales-onboarding/assets/button_frills@2x.png"
                  width="16"
                  height="48"
                  style="transform: scaleX(-1)"
                  class="ml-1 mr-3 d-none d-lg-inline"
                />
              </template>
            </div>
          </div>
          <div class="row no-gutters presales-onboarding__top-bar-2">
            <div class="col-12 col-md-auto text-center text-start-md mt-2 mt-md-0">
              This takes a while because we value:
            </div>
            <div class="col-12 col-md-auto d-flex justify-content-center align-items-center mb-2 mb-md-0">
              <div class="d-flex align-items-center ml-0 ml-md-4">
                <svg fill="none" height="16" viewBox="0 0 13 16" width="13" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m6.32407 15.9601c-6.137031-3.0952-6.3794374-7.20425-6.31735758-13.88225.00295623-.17442.12120358-.31927.27788058-.36361l6.110417-1.6998074c.07095-.0206932.1419-.01773707.20694 0l6.10455 1.6998074c.1714.04729.2838.20693.2778.37839.0385 3.55924-.062 6.25823-.9046 8.45467-.8602 2.2349-2.46245 3.9228-5.41272 5.4128-.11233.0561-.23945.0502-.34291 0z"
                    fill="#575757"
                  />
                </svg>
                <span class="ml-2">Security</span>
              </div>
              <div class="d-flex align-items-center ml-3">
                <svg fill="none" height="14" viewBox="0 0 20 14" width="20" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#575757">
                    <path
                      d="m10 9.39966c1.3807 0 2.5-1.11929 2.5-2.5s-1.1193-2.5-2.5-2.5c-1.38071 0-2.5 1.11929-2.5 2.5s1.11929 2.5 2.5 2.5z"
                    />
                    <path
                      d="m10 .333252c-2.13877 0-4.22969.633079-6.00925 1.819458-1.77957 1.18637-3.168146 2.87296-3.99075 4.84721.822604 1.97425 2.21118 3.66088 3.99075 4.84718 1.77956 1.1864 3.87048 1.8195 6.00925 1.8195 2.1388 0 4.2297-.6331 6.0093-1.8195 1.7795-1.1863 3.1681-2.87293 3.9907-4.84718-.8226-1.97425-2.2112-3.66084-3.9907-4.84721-1.7796-1.186379-3.8705-1.819458-6.0093-1.819458zm0 10.733348c-.82409 0-1.62967-.2444-2.31488-.7022-.6852-.45786-1.21925-1.10861-1.53462-1.86997-.31536-.76136-.39788-1.59913-.23711-2.40739.16078-.80825.55761-1.55068 1.14033-2.1334s1.32515-.97956 2.1334-1.14033c.80826-.16077 1.64608-.07825 2.40738.23711.7614.31537 1.4121.84942 1.87 1.53462.4578.68521.7022 1.49079.7022 2.31488 0 1.10507-.439 2.16487-1.2204 2.94628-.7814.7814-1.8412 1.2204-2.9463 1.2204z"
                    />
                  </g>
                </svg>
                <span class="ml-2">Data privacy</span>
              </div>
              <div class="d-flex align-items-center ml-3">
                <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m8 0c-4.41828 0-8 3.58175-8 7.99998 0 4.41822 3.58172 8.00002 8 8.00002 4.4183 0 8-3.5818 8-8.00002 0-4.41823-3.5817-7.99998-8-7.99998zm0 3.38462c.33988 0 .61539.2755.61539.61538v3.74998l2.21151 2.21154c.2403.24038.2403.62508 0 .86538s-.6251.2403-.86536 0c-.79422-.7975-1.59614-1.61489-2.39423-2.39424-.11136-.11133-.18269-.26274-.18269-.43268v-3.99998c0-.33988.2755-.61538.61538-.61538z"
                    fill="#575757"
                  />
                </svg>
                <span class="ml-2">Reliability</span>
              </div>
            </div>
          </div>
          <div class="presales-onboarding__top-bar-spacer-2" />

          <div100vh :top="128">
            <typeform-embed form-id="uBPoNK" :hidden-fields="{ email: currentUser.email }" />
          </div100vh>
        </div>
      </div>

      <spinner-overlay v-if="isSaving" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import camelize from "camelize";
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers } from "vuelidate/lib/validators";

import SpinnerOverlay from "./spinner-overlay";
import CloudinaryTransmit from "./cloudinary-transmit";
import ColorModal from "./app-editor/color-modal";
import { luminance } from "utils";
import ColorScheme from "./presales-onboarding/color-scheme";
import TopBar from "./presales-onboarding/top-bar";
import CommunityPreviewColumn from "./presales-onboarding/community-preview-column";
import TypeformEmbed from "./presales-onboarding/typeform-embed";
import BuildingProgressIndicator from "./presales-onboarding/building-progress-indicator";
import Div100vh from "./div100vh";
import Steps from "./presales-onboarding/steps";

export default {
  components: {
    SpinnerOverlay,
    CloudinaryTransmit,
    ColorModal,
    ColorScheme,
    TopBar,
    CommunityPreviewColumn,
    TypeformEmbed,
    BuildingProgressIndicator,
    Div100vh,
    Steps,
  },
  mixins: [validationMixin],
  data() {
    return {
      currentUser: null,
      currentUserCountry: null,

      isLoading: true,
      isSaving: false,
      currentTab: null,

      name: "",

      logoPath: null,

      primaryColor: "#FFFFFF",
      secondaryColor: "#313131",
      actionColor: "#00B2FF",
      theme: "light",
      isColorSchemeExpanded: false,

      appName: null,
      backendStatus: null,
    };
  },
  asyncComputed: {
    async masterColors() {
      const response = await $.get("/api/color_preview", {
        colors: {
          simple_enabled: true,
          simple_primary: this.primaryColor,
          simple_secondary: this.secondaryColor,
          simple_action: this.actionColor,
          simple_theme: this.theme,
        },
      });

      if (response.result === "failure") {
        return null;
      }

      return response.master_colors;
    },
  },
  computed: {
    isNotFirstCommunity() {
      const params = new URLSearchParams(location.search);
      return params.get("freetrial") === "expiredover30days";
    },
    communityUrl() {
      if (!this.appName) {
        return;
      }

      const url = new URL(`https://${this.appName}.disciplemedia.com`);
      url.searchParams.append("customer", true);
      url.searchParams.append("email", this.currentUser.email);

      return url.toString();
    },
    generatedCommunityUrl() {
      const prefix = this.name
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9-_ ]/, "")
        .replace(/\s/g, "")
        .substring(0, 15);
      if (prefix.length === 0) {
        return;
      }

      return `https://${prefix}.disciplemedia.com`;
    },
    logoTopText() {
      if (this.logoPath) {
        return "OK got it. Your logo has been uploaded.";
      } else {
        return "Upload your community logo if you have one.";
      }
    },
    logoUrl() {
      if (!this.logoPath) {
        return null;
      }

      return $.cloudinary.url(this.logoPath);
    },
    selectedColorScheme: {
      get() {
        return _.find(this.colorSchemes, (scheme) => {
          return (
            scheme.primaryColor === this.primaryColor &&
            scheme.secondaryColor === this.secondaryColor &&
            scheme.actionColor === this.actionColor &&
            scheme.theme === this.theme
          );
        });
      },
      set(newVal) {
        this.primaryColor = newVal.primaryColor;
        this.secondaryColor = newVal.secondaryColor;
        this.actionColor = newVal.actionColor;
        this.theme = newVal.theme;
      },
    },
    colorSchemes() {
      return require("static/color_schemes.json");
    },
    isDarkTheme: {
      get() {
        return this.theme === "dark";
      },
      set(newVal) {
        this.theme = newVal ? "dark" : "light";
      },
    },
  },
  watch: {
    currentTab(newVal, oldVal) {
      this.validateTabChange(oldVal, newVal, true);

      window.location.hash = this.currentTab;

      const $tab = $(`.tab-content .tab-pane${this.currentTab}`, this.$el);
      $tab.show();
      $tab.siblings(".tab-pane").hide();

      if (this.currentTab !== "#name" && this.backendStatus !== "ready") {
        this.pollClient();
      }
    },
    selectedColorScheme() {
      if (!this.selectedColorScheme) {
        this.isColorSchemeExpanded = true;
      }
    },
  },
  beforeMount() {
    this.currentUser = camelize(JSON.parse(this.$el.dataset.currentUser));
    this.discipleLogoEl = this.$el.firstElementChild;
  },
  created() {
    this.$bus.$on("presales-onboarding-steps:change-tab", (step) => {
      if (step === 1) {
        this.changeTab("#name");
      } else if (step === 2) {
        this.changeTab("#logo");
      } else if (step === 3) {
        this.changeTab("#colours");
      }
    });
  },
  mounted() {
    this.loadState().then(() => {
      this.isLoading = false;

      this.$nextTick(() => this.setCurrentTabFromHash());
    });

    window.addEventListener(
      "hashchange",
      () => {
        this.setCurrentTabFromHash();
      },
      false,
    );
  },
  methods: {
    setCurrentTabFromHash() {
      this.currentTab = $.trim(window.location.hash) || "#name";
    },
    validateTabChange(previousTab, nextTab, preventNavigation) {
      if (this.$v.name.$invalid) {
        if (preventNavigation) {
          this.currentTab = "#name";
        }
        return false;
      }

      if (nextTab === "#name") {
        if (this.backendStatus === "ready") {
          if (preventNavigation) {
            this.currentTab = "#building";
          }
          return false;
        }

        if (this.backendStatus !== "unknown") {
          if (preventNavigation) {
            this.currentTab = "#logo";
          }
          return false;
        }
      }

      return true;
    },
    changeTab(tab) {
      this.isSaving = true;
      this.saveState().then(() => {
        this.isSaving = false;
        this.currentTab = tab;
      });
    },
    async loadState() {
      await this.loadClientState();
    },
    async loadClientState(fields) {
      const response = await $.get("/api/presales_client");
      this.updateClientStateFromResponse(response, fields);
    },
    async saveState() {
      await this.saveClientState();
    },
    async saveClientState() {
      const response = await $.ajax({
        method: "PATCH",
        url: "/api/presales_client",
        data: JSON.stringify(
          _.pickBy({
            name: this.name,
            logo_path: this.logoPath,
            primary_color: this.primaryColor,
            secondary_color: this.secondaryColor,
            action_color: this.actionColor,
            theme: this.theme,
          }),
        ),
        contentType: "application/json; charset=UTF-8",
      });
      this.updateClientStateFromResponse(response);
    },
    updateClientStateFromResponse(response, fields) {
      if (response.name && (!fields || _.includes(fields, "name"))) {
        this.name = response.name;
      }
      if (response.logo_path && (!fields || _.includes(fields, "logo_path"))) {
        this.logoPath = response.logo_path;
      }
      if (response.primary_color && (!fields || _.includes(fields, "primary_color"))) {
        this.primaryColor = response.primary_color;
      }
      if (response.secondary_color && (!fields || _.includes(fields, "secondary_color"))) {
        this.secondaryColor = response.secondary_color;
      }
      if (response.action_color && (!fields || _.includes(fields, "action_color"))) {
        this.actionColor = response.action_color;
      }
      if (response.theme && (!fields || _.includes(fields, "theme"))) {
        this.theme = response.theme;
      }
      if (response.backend_status && (!fields || _.includes(fields, "backend_status"))) {
        this.backendStatus = response.backend_status;
      }
      if (response.app_name && (!fields || _.includes(fields, "app_name"))) {
        this.appName = response.app_name;
      }
    },
    pollClient() {
      let running = false;
      const timer = setInterval(async () => {
        if (!running) {
          running = true;

          await this.loadClientState(["backend_status"]);
          if (this.backendStatus === "ready") {
            clearInterval(timer);
          }

          running = false;
        }
      }, 5000);
    },
    contrastingTextColor(hexColor) {
      if (luminance(hexColor) > 186) {
        return "black";
      } else {
        return "white";
      }
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(30),
      characters: helpers.regex("name", /^[ a-zA-Z0-9]*$/),
      startsWithAlpha: helpers.regex("startsWithAlpha", /^[a-zA-Z]/),
    },
  },
};
</script>

<style lang="scss" scoped>
.presales-onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }

  &__top-bar {
    height: 64px;

    background: #f5f5f5;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__top-bar-2 {
    margin-top: 64px;
    height: 64px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;

    background: #fafafa;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  &__top-bar-spacer-1 {
    margin-top: calc(64px + 32px);

    @media (min-height: 1080px) {
      margin-top: calc(64px + 128px);
    }
  }

  &__top-bar-spacer-2 {
    margin-top: 128px;
  }

  &__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 115%;
    color: #0f545c;

    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  & ::v-deep .community-preview__frame {
    @media (max-width: 575px) {
      transform: scale(0.8);
      // Margins are not affected by transform so we adjust by 315 px height * 0.8
      margin-top: -63px;
      margin-bottom: -63px;
    }
  }

  &__dropzone {
    cursor: pointer;
  }

  &__button {
    background: #f26b4f;

    font-weight: bold;

    height: 42px;
    width: 57px;

    @media (min-width: 1024px) {
      width: 64px;
    }

    &--disabled {
      opacity: 0.4;
    }

    &--flash {
      animation: 0.25s 3 flash;

      @keyframes flash {
        0% {
          background-color: #87c4ba;
        }
        50% {
          background-color: #0f545c;
        }
        100% {
          background-color: #87c4ba;
        }
      }
    }
  }

  &__small-text {
    line-height: 150%;
    color: #0f545c;
    font-weight: normal;
    font-size: 0.875rem;
  }

  &__arrow {
    height: 19px;
    width: 58px;
    margin-top: 3px;
    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='19' viewBox='0 0 58 19' width='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-rule='evenodd' fill='%230F545C' fill-rule='evenodd'%3E%3Cpath d='m56.9952 6.93633c.4161 1.03156-.0799 2.20642-1.108 2.62414-16.6378 6.76013-32.7701 6.82023-53.5748-1.032-1.03807-.39179-1.562968-1.55384-1.17239-2.59552.39057-1.04168 1.54872-1.56851 2.58679-1.17671 20.0075 7.55136 35.086 7.39386 50.6533 1.06866 1.0281-.41772 2.1989.07988 2.6151 1.11143z'/%3E%3Cpath d='m48.4911 18.6463c-.9147-.6294-1.1476-1.8837-.5204-2.8016l5.0102-7.33097-8.2237-4.83305c-.957-.56244-1.2784-1.7969-.7178-2.757237.5606-.9603413 1.7908-1.282905 2.7478-.720464l10.0846 5.926711c.481.28269.8233.75328.9449 1.29917.1215.54589.0115 1.11795-.3037 1.57927l-6.2299 9.11577c-.6273.9179-1.8773 1.1518-2.792.5224z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .btn {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));

    &:disabled {
      filter: none;
    }
  }
}

.tab-content {
  .tab-pane {
    padding: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    margin-bottom: -7px;
  }
}

.logo-img {
  width: 164px;
  height: 164px;

  border-radius: 20px;

  object-fit: cover;
  object-position: center;
}
</style>
