<template>
  <transition name="slidein">
    <div v-show="shown" class="flash-notification notification notification-success">
      <span v-if="hiding">{{ hidingText }}</span>
      <span v-else>{{ shownText }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    shownText: {
      type: String,
      required: true,
    },
    hidingText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shown: false,
      hiding: false,
      timeoutId: null,
    };
  },
  watch: {
    trigger() {
      if (this.trigger) {
        this.show();
      } else {
        this.hide();
      }
    },
  },
  methods: {
    show() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.shown = true;
      this.hiding = false;
    },
    hide() {
      this.hiding = true;
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null;
        this.shown = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.flash-notification {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
}

.slidein-enter-active {
  animation: 0.2s slideDown;
}

.slidein-leave-active {
  animation: 0.2s slideUp;
}

@keyframes slideDown {
  from {
    transform: translateY(35px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(35px);
  }
}
</style>
