<template>
  <div>
    <spinner-overlay v-if="isBusy" />

    <h1 class="font-weight-bold text-center">Verify your device</h1>
    <p>A verification code has been sent to {{ options.email }}.</p>

    <b-form-group label="Code*" label-for="code">
      <b-form-input id="code" v-model="code" @keydown.native.enter="handleVerify" />
    </b-form-group>

    <div class="form-row">
      <div class="col-auto ml-auto">
        <a href="#" class="login-form__link" @click="handleResend">Resend code</a>
      </div>
    </div>
    <div class="form-row">
      <div class="col-auto ml-auto">
        <a
          href="https://resources.disciplemedia.com/en/what-if-i-dont-receive-a-two-factor-authentication-code"
          class="login-form__link"
          rel="noopener noreferrer"
          target="_blank"
          >Didn’t get the email?</a
        >
      </div>
    </div>
    <div v-if="options.available_methods.includes('webauthn')" class="form-row">
      <div class="col-auto ml-auto">
        <a href="#" class="login-form__link" @click="handleSwitchToWebauthn">Verify with passkey</a>
      </div>
    </div>

    <div class="form-row my-5">
      <div class="col-auto mx-auto">
        <button :disabled="$v.code.$invalid" class="btn btn-primary login-form__button" @click="handleVerify">
          Verify
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import SpinnerOverlay from "app_manager/components/spinner-overlay";

export default {
  components: {
    SpinnerOverlay,
  },
  mixins: [validationMixin],
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      code: "",

      isBusy: false,
    };
  },
  created() {
    this.createChallenge();
  },
  methods: {
    async handleVerify() {
      this.isBusy = true;
      try {
        const response = await $.ajax({
          method: "POST",
          url: "/users/email_verification/verify.json",
          data: JSON.stringify({ code: this.code }),
          contentType: "application/json",
        });

        if (response.result === "redirect") {
          window.location = response.next;
        } else if (response.result == "invalid_code") {
          this.$bvToast.toast("The code you entered is not valid.", {
            variant: "danger",
            title: "Verification failed",
          });
          this.code = "";
        } else if (response.result === "expired_code") {
          this.$bvToast.toast("The code you entered has expired.", {
            variant: "danger",
            title: "Verification failed",
          });
          this.code = "";
        }
      } catch (e) {
        this.$bvToast.toast("The request has failed.", {
          variant: "danger",
          title: "Server error",
        });
      } finally {
        this.isBusy = false;
      }
    },
    async handleResend() {
      this.isBusy = true;
      try {
        if (!(await this.createChallenge({ resend: true }))) {
          return;
        }
        this.code = "";
        this.$bvToast.toast(`A new code has been sent to ${this.options.email}`, {
          variant: "success",
          title: "Email resent",
        });
      } catch (e) {
        this.$bvToast.toast("The request has failed.", {
          variant: "danger",
          title: "Server error",
        });
      } finally {
        this.isBusy = false;
      }
    },
    async createChallenge(args = {}) {
      const response = await $.ajax({
        method: "POST",
        url: "/users/email_verification.json",
        data: JSON.stringify(args),
        contentType: "application/json",
      });

      if (response.result === "redirect") {
        window.location.href = response.next;
        return false;
      } else {
        return true;
      }
    },
    handleSwitchToWebauthn() {
      this.$emit("switch-to-webauthn");
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
    },
  },
};
</script>
