<template>
  <form ref="form" class="applications-search" action="/applications" method="get">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="icon-magnifier" />
        </div>
      </div>
      <input
        id="query"
        ref="input"
        v-model="query"
        name="query"
        type="text"
        placeholder="Search communities"
        class="form-control"
        spellcheck="false"
      />
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      query: null,
    };
  },
  beforeMount() {
    this.query = this.$el.dataset.query;
  },
  mounted() {
    $(document).keyup((e) => {
      if ($(e.target).not("input") && e.key === "/") {
        $(this.$refs.input).focus();
      }
    });
  },
};
</script>
