<template lang="slm">
  .disciple-card.feature-card
    .row
      .col-9
        .disciple-card__header
          slot name="header"
        div
          slot name="description"
      .col-3.d-flex.justify-content-end v-if="!forceEnabled"
        label.disciple-switch
          input.disciple-switch__input type="checkbox" v-model="enabled"
          span.disciple-switch__label data-on="On" data-off="Off"
          span.disciple-switch__handle
    .row v-if="isCustomTextEnabled"
      .col-4.text-label.d-flex.align-items-center
        . {{textLabel}}
        a.d-flex.align-items-center.ml-3.no-decoration[
          v-if="textLabelHelplink"
          :href="textLabelHelplink"
          target="_blank"
        ]
          i.icon-question
      .col-8
        input.form-control type="text" v-model="tempText" :placeholder="textPlaceholder"
    .row v-for="feature in secondaryFeatures" :key="feature.value" v-if="enabled"
      .col-4
        . {{feature.label}}
      .col-8
        label.disciple-switch
          input.disciple-switch__input type="checkbox" v-model="secondaryFeatureValues[feature.value]"
          span.disciple-switch__label data-on="On" data-off="Off"
          span.disciple-switch__handle
    .row v-if="noticeMessage"
      .col
        span.disciple-card__notice {{noticeMessage}}
    .row v-if="errorMessage"
      .col
        span.disciple-card__error {{errorMessage}}
</template>

<script>
import _ from "lodash";

import { emailRegex } from "utils";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    textLabel: {
      type: String,
      required: false,
      default: null,
    },
    textLabelHelplink: {
      type: String,
      required: false,
      default: null,
    },
    textPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    forceEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    textType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return value === "email" || value === "url";
      },
    },
    allowBlank: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondaryFeatures: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      errorMessage: null,
      noticeMessage: null,
      tempText: this.value.text,
      secondaryFeatureValues: this.value.secondaryFeatures || {},
      previousSecondaryFeatureValues: {},
    };
  },
  computed: {
    enabled: {
      get() {
        return this.value.enabled;
      },
      set(newVal) {
        this.$emit("input", Object.assign({}, this.value, { enabled: newVal }));

        if (newVal) {
          this.validateValue(this.text);
        } else {
          this.errorMessage = null;
        }
      },
    },
    text: {
      get() {
        return this.value.text;
      },
      set(newVal) {
        this.$emit("input", Object.assign({}, this.value, { text: newVal }));
      },
    },
    isCustomTextEnabled() {
      return Boolean(this.textLabel) && this.enabled;
    },
  },
  watch: {
    value() {
      if (this.forceEnabled && !this.value.enabled) {
        this.enabled = true;
      }

      this.tempText = this.text;

      if (this.value.secondaryFeatures) {
        this.secondaryFeatureValues = this.value.secondaryFeatures;
      }
    },
    tempText(newVal) {
      if (this.validateValue(newVal)) {
        this.applyText(newVal);
      }
    },
    secondaryFeatureValues: {
      deep: true,
      handler() {
        // When deep watching an object newVal == oldVal (it's the
        // same object) so this workaround is necessary.
        if (_.isEqual(this.secondaryFeatureValues, this.previousSecondaryFeatureValues)) {
          return;
        }

        this.previousSecondaryFeatureValues = this.secondaryFeatureValues;
        this.$emit(
          "input",
          Object.assign({}, this.value, {
            secondaryFeatures: this.secondaryFeatureValues,
          }),
        );
      },
    },
  },
  mounted() {
    if (this.forceEnabled && !this.value.enabled) {
      this.enabled = true;
    }
  },
  methods: {
    applyText(newVal) {
      this.noticeMessage = null;
      this.errorMessage = null;
      this.text = newVal;
    },
    validateValue(value) {
      if (!value || value.length === 0) {
        if (this.allowBlank) {
          return true;
        } else {
          this.errorMessage = "Cannot be blank.";
          return false;
        }
      }

      if (this.textType === "email") {
        if (value.match(emailRegex)) {
          return true;
        } else {
          this.errorMessage = `${value} is not a valid email address.`;
          return false;
        }
      }

      if (this.textType === "url") {
        if (/http[s]?:\/\/[^\s]+/.test(value)) {
          return true;
        } else {
          this.errorMessage = `${value} is not a valid URL.`;
          return false;
        }
      }

      return true;
    },
  },
};
</script>
