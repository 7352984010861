<template>
  <div v-if="!isLoading" class="app-editor mt-6">
    <custom-b-tabs @input="handleTabChange">
      <b-tab title="Colours">
        <color-configuration-manager :client-id="clientId" :current-user="currentUser" />
      </b-tab>

      <b-tab title="Images">
        <div class="row card-row">
          <div class="col">
            <asset-card
              v-model="settings.assets.appIcon"
              asset-format="PNG"
              :asset-width="2048"
              :asset-height="2048"
              :asset-transparent="false"
              :rounded="true"
            >
              <h4 slot="header">App icon</h4>
              <p slot="description">
                Upload the icon for your community app for your members to identify your community app on their device.
              </p>
              <img slot="assetExample" src="./app-editor/asset-card/app_icon.png" />
            </asset-card>
          </div>
        </div>

        <div v-if="isMobileAvailable" class="row card-row">
          <div class="col">
            <asset-card
              v-model="settings.assets.launchScreen"
              :asset-height="2208"
              :asset-transparent="false"
              :asset-width="1242"
              asset-format="PNG"
            >
              <h4 slot="header">Launch screen</h4>
              <p slot="description">
                Personalise the image displayed when your community app boots, on the login/signup page and on the
                welcome screen.
              </p>
              <img slot="assetExample" src="./app-editor/asset-card/launch_screen.png" />
            </asset-card>
          </div>
        </div>

        <div class="row card-row">
          <div class="col">
            <asset-card
              v-model="settings.assets.openGraphImage"
              :asset-height="630"
              :asset-width="1200"
              asset-dimensions-prefix="minimum"
              asset-format="JPG, PNG"
            >
              <h4 slot="header">Preview image</h4>
              <p slot="description">
                Customise the default image that is displayed when someone shares a link to your community on social
                media or on messaging apps.
                <br />
                <br />
                Depending on the platform the link is shared to, the image may appear cropped. We recommend a 1.91:1
                ratio to minimise the cropping of your image, however this may vary.
              </p>
            </asset-card>
          </div>
        </div>

        <div class="row card-row">
          <div class="col">
            <asset-card
              v-model="settings.assets.headerLogo"
              :asset-height="120"
              :asset-transparent="true"
              :asset-width="900"
              asset-format="PNG"
            >
              <h4 slot="header">Header logo</h4>
              <p slot="description">Add your logo to the top bar of your Android and iOS app.</p>
              <img slot="assetExample" src="./app-editor/asset-card/header_logo.png" />
            </asset-card>
          </div>
        </div>

        <div v-if="isAndroidAvailable" class="row card-row">
          <div class="col">
            <asset-card
              v-model="settings.assets.androidNotificationIcon"
              :asset-height="96"
              :asset-transparent="true"
              :asset-width="96"
              asset-format="PNG"
            >
              <h4 slot="header">Android notification icon</h4>
              <p slot="description">
                Personalise the notification icon on Android device status bar when a new notification is coming up.
              </p>
              <img slot="assetExample" src="./app-editor/asset-card/android_notif.png" />
            </asset-card>
          </div>
        </div>
      </b-tab>

      <b-tab title="Other">
        <div class="row card-row">
          <div class="col">
            <text-card v-model="settings.features.appName">
              <h4 slot="header">Name of your community</h4>
            </text-card>
          </div>
        </div>

        <div v-if="areLanguagesAvailable" class="row card-row">
          <div class="col">
            <div class="disciple-card" style="display: flex; justify-content: center">
              <div class="col-4 mt-2">
                <h4 class="disciple-card__header">Community language</h4>
              </div>

              <div class="col-8">
                <b-form-select
                  id="communityLanguage"
                  v-model="settings.features.communityLanguage"
                  name="communityLanguage"
                  :options="availableLanguages"
                />
              </div>
            </div>
          </div>
        </div>

        <custom-domain-manager v-if="isCustomDomainAvailable" :client-id="clientId" />

        <div v-if="isWatermarkAvailable" class="row card-row">
          <div class="col">
            <feature-card v-model="settings.assets.discipleWatermark">
              <h4 slot="header">Disciple watermark</h4>
              <p slot="description">
                We're proud of your beautiful community app and want to tell the world we made it. But it's up to you.
                Choose whether or not to show our logo in the left navigation panel of your community app.
              </p>
            </feature-card>
          </div>
        </div>

        <div class="row card-row">
          <div class="col">
            <feature-card
              v-model="settings.integration.dedicatedSupportEmailAddress"
              :allow-blank="false"
              :force-enabled="true"
              text-label="Email address"
              text-type="email"
            >
              <h4 slot="header">Dedicated support email address</h4>
              <p slot="description">
                Enter the email your community members will be able to contact you on for any inquiries they may have.
              </p>
            </feature-card>
          </div>
        </div>
      </b-tab>
    </custom-b-tabs>

    <flash-notification
      :trigger="!settingsSaved"
      shown-text="Saving..."
      hiding-text="Changes will be live within 10 minutes."
    />
  </div>
</template>

<script>
import AssetCard from "./app-editor/asset-card";
import TextCard from "./app-editor/text-card";
import FeatureCard from "./app-editor/feature-card";
import FlashNotification from "./flash-notification";
import CustomDomainManager from "./custom-domain-manager";
import ColorConfigurationManager from "./color-configuration-manager";
import CustomBTabs from "./custom-b-tabs";

import deepMerge from "deepmerge";
import _ from "lodash";

import "./app-editor/asset-card/android_notif.png";
import "./app-editor/asset-card/app_icon.png";
import "./app-editor/asset-card/header_logo.png";
import "./app-editor/asset-card/launch_screen.png";

export default {
  components: {
    AssetCard,
    TextCard,
    FeatureCard,
    FlashNotification,
    CustomDomainManager,
    ColorConfigurationManager,
    CustomBTabs,
  },
  data() {
    return {
      settingsSaved: true,
      isLoading: true,
      settings: {
        features: {
          appName: null,
          communityLanguage: this.currentLanguage,
        },
        featuresOrder: [],
        assets: {
          appIcon: null,
          launchScreen: null,
          openGraphImage: null,
          headerLogo: null,
          androidNotificationIcon: null,
          discipleWatermark: {
            enabled: false,
            text: null,
          },
        },
        integration: {
          dedicatedSupportEmailAddress: {
            enabled: false,
            text: null,
          },
        },
      },
      clientId: null,
      clientSettingsUrl: null,
      isMobileAvailable: null,
      isAndroidAvailable: null,
      isWatermarkAvailable: null,
      areLanguagesAvailable: false,
      isCustomDomainAvailable: false,

      currentUser: null,
    };
  },
  computed: {
    finishEditingButtonText() {
      if (this.onboarding) {
        return "Finish";
      } else {
        return "Preview";
      }
    },
  },
  watch: {
    settings: {
      handler() {
        if (this.isLoading) {
          return;
        }
        this.settingsSaved = false;
        this.saveSettings();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clientId = this.$el.dataset.clientId;
    this.clientSettingsUrl = this.$el.dataset.clientSettingsUrl;
    this.availableLanguages = JSON.parse(this.$el.dataset.availableLanguages);
    this.currentLanguage = JSON.parse(this.$el.dataset.currentLanguage);
    this.isMobileAvailable = this.$el.dataset.mobileAvailable === "true";
    this.isAndroidAvailable = this.$el.dataset.androidAvailable === "true";
    this.isWatermarkAvailable = this.$el.dataset.watermarkAvailable === "true";
    this.areLanguagesAvailable = this.$el.dataset.availableLanguages.length > 1;
    this.isCustomDomainAvailable = this.$el.dataset.customDomainAvailable === "true";
    this.currentUser = JSON.parse(this.$el.dataset.currentUser);
  },
  mounted() {
    this.loadSettings().then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    loadSettings() {
      return $.get(this.clientSettingsUrl).done((data) => {
        this.settings = deepMerge(this.settings, data);
      });
    },
    saveSettings: _.debounce(function () {
      $.ajax(this.clientSettingsUrl, {
        method: "PUT",
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(this.settings),
      }).done(() => (this.settingsSaved = true));
    }, 500),
    handleTabChange() {
      // Emit resize event so components in the newly activated tab know they are visible.
      this.$bus.$emit("resize");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-row {
  margin-top: 0.75rem;

  &:last-child {
    margin-bottom: 0.75rem;
  }
}

.disciple-card {
  & ::v-deep .row + .row {
    margin-bottom: 0.5rem;
  }
}
</style>
