<template>
  <b-tabs
    no-nav-style
    content-class="custom-b-tabs__content"
    nav-class="custom-b-tabs__nav"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
  </b-tabs>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss">
.custom-b-tabs {
  &__nav {
    height: 28px;

    display: flex;

    .nav-item {
      flex: 1;
    }

    .nav-link {
      font-size: 0.875rem;
      text-align: center;
      padding-top: 0;
      color: rgba(15, 84, 92, 0.8);
      border-bottom: 1px solid rgba(15, 84, 92, 0.2);

      &.active {
        border-bottom: 1px solid #f26b4f;
        font-weight: bold;
        color: #f26b4f;
      }
    }
  }

  &__content {
    margin-top: 2rem;
    background: inherit;
    border: inherit;

    .tab-pane {
      padding: inherit;
    }
  }
}
</style>
