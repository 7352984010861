export const stateMixin = ({ states }) => {
  const statesObject = states.reduce((obj, val) => {
    obj[val] = val;
    return obj;
  }, {});

  return {
    data: function () {
      return {
        state: statesObject[states[0]],
        STATES: statesObject,
      };
    },
  };
};
