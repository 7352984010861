<template>
  <vue-transmit
    ref="uploader"
    :url="cloudinaryUploadUrl"
    :accepted-file-types="acceptedFileTypes"
    response-type="json"
    @accepted-file="handleFileAccepted"
    @sending="handleFileSend"
    @success="handleSuccess"
  >
    <slot />
    <spinner-overlay v-if="isUploading" />
  </vue-transmit>
</template>

<script>
import SpinnerOverlay from "./spinner-overlay";

export default {
  components: {
    SpinnerOverlay,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    acceptedFileTypes: {
      type: Array,
      required: true,
    },
    uploadPreset: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isUploading: false,
    };
  },
  computed: {
    cloudinaryUploadUrl() {
      return `https://api.cloudinary.com/v1_1/${$.cloudinary.config().cloud_name}/upload`;
    },
  },
  methods: {
    handleFileAccepted() {
      this.isUploading = true;
    },
    handleFileSend(_file, _xhr, formData) {
      formData.append("upload_preset", this.uploadPreset);
    },
    handleSuccess(_file, response) {
      this.$emit("input", `v${response.version}/${response.public_id}.${response.format}`);
      this.isUploading = false;
    },
    triggerBrowseFiles() {
      this.$refs.uploader.triggerBrowseFiles();
    },
  },
};
</script>
