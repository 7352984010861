<template>
  <div ref="container" style="height: 100%" />
</template>

<script>
import { createWidget } from "@typeform/embed";

import "@typeform/embed/build/css/widget.css";

export default {
  props: {
    formId: {
      type: String,
      required: true,
    },
    hiddenFields: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    createWidget(this.formId, {
      container: this.$refs.container,
      hidden: this.hiddenFields,
    });
  },
};
</script>
