<template>
  <div class="d-flex flex-column justify-content-start align-items-center">
    <b-btn
      v-for="category in menuItems"
      :key="category.label"
      class="nav-category p-0 d-flex flex-column justify-content-center align-items-center"
      :class="{
        desktop: $mq === 'lg',
        active: isCategoryActive(category),
      }"
      square
      @click="setActiveCategory(category)"
    >
      <div
        class="category-icon-wrapper d-flex justify-content-center align-items-center"
        :class="{ desktop: $mq === 'lg' }"
        :style="{ '--background-color': isCategoryActive(category) ? category.color : 'var(--color-white)' }"
      >
        <img :src="isCategoryActive(category) ? category.active_icon_url : category.icon_url" />
      </div>
      <div v-if="$mq === 'lg'" class="mt-1">{{ category.label }}</div>
    </b-btn>
  </div>
</template>

<script>
import { isLinkActive } from "./navigation-helper.js";

export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
    };
  },
  created() {
    this.$bus.$on("activate-nav-category", this.onActivateNavCategory);
  },
  destroyed() {
    this.$bus.$off("activate-nav-category", this.onActivateNavCategory);
  },
  mounted() {
    this.activeCategory = this.getCategoryFromRoute();
    this.setActiveCategory(this.activeCategory);
  },
  methods: {
    getCategoryFromRoute() {
      const activeCategoryMatchingRoute = this.menuItems.find((category) => category.links.find(isLinkActive));

      return this.$mq === "lg" ? activeCategoryMatchingRoute || null : activeCategoryMatchingRoute || this.menuItems[0];
    },
    onActivateNavCategory(category) {
      this.activeCategory = category;
    },
    async setActiveCategory(category) {
      this.activeCategory = category;
      this.$bus.$emit("activate-nav-category", category);
    },
    isCategoryActive(category) {
      return this.activeCategory?.label === category.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-category {
  width: 100% !important;
  min-width: 63px !important;
  min-height: 64px !important;
  border: 0;
  font-size: 14px;
  background-color: initial !important;
  color: var(--color-tint) !important;
  border-radius: 0;

  &.desktop {
    min-height: 96px !important;
  }

  &.active {
    background-color: var(--color-white) !important;
  }

  &:not(.active) {
    font-weight: normal !important;
  }

  &::before {
    display: none;
  }

  &:focus-visible,
  &:hover {
    .category-icon-wrapper {
      box-shadow:
        0px 0.5px 5px rgba(0, 0, 0, 0.08),
        0px 2px 2px rgba(0, 0, 0, 0.05);
    }
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.category-icon-wrapper {
  width: 48px;
  height: 48px;

  &.desktop {
    width: 56px;
    height: 56px;
  }

  border-radius: 50% !important;
  background-color: var(--background-color);
}

.nav-category-toggle {
  height: 48px !important;
}
</style>
