<template>
  <b-navbar v-if="activeCategory" column class="flex-grow-1 pt-0 pb-2">
    <b-navbar-nav class="px-2 d-flex flex-column">
      <div class="category-title-wrapper d-flex align-items-center mx-3">
        <img class="mr-3" :color="activeCategory.color" :src="activeCategory.icon_url" />
        <h2 class="mb-0 font-size-title-small font-weight-normal">{{ activeCategory.label }}</h2>
      </div>
      <b-dropdown-divider class="width-limit color-light-gray" />
      <b-list-group nav class="py-0" :class="{ 'px-0': $mq === 'lg' }">
        <b-list-group-item
          v-for="link in visibleLinks"
          :key="link.label"
          :href="link.url"
          :active="isLinkActive(link)"
          :locked="link.locked"
          :target="link.external_link ? '_blank' : null"
          class="width-limit mt-2 d-flex align-items-center"
          exact
        >
          <div class="d-flex align-items-center justify-items-center gap-2">
            <div v-if="link.locked" class="lock-container d-flex flex-shrink-0 justify-center">
              <svg
                class="app-icon"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 6H11V3.5C11 2.70435 10.6839 1.94129 10.1213 1.37868C9.55871 0.81607 8.79565 0.5 8 0.5C7.20435 0.5 6.44129 0.81607 5.87868 1.37868C5.31607 1.94129 5 2.70435 5 3.5V6H4.5C3.96974 6.00058 3.46137 6.21148 3.08643 6.58643C2.71148 6.96137 2.50058 7.46974 2.5 8V13.5C2.50058 14.0303 2.71148 14.5386 3.08643 14.9136C3.46137 15.2885 3.96974 15.4994 4.5 15.5H11.5C12.0303 15.4994 12.5386 15.2885 12.9136 14.9136C13.2885 14.5386 13.4994 14.0303 13.5 13.5V8C13.4994 7.46974 13.2885 6.96137 12.9136 6.58643C12.5386 6.21148 12.0303 6.00058 11.5 6ZM10 6H6V3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5C8.53043 1.5 9.03914 1.71071 9.41421 2.08579C9.78929 2.46086 10 2.96957 10 3.5V6Z"
                ></path>
              </svg>
            </div>
            <span class="truncate">{{ link.label }}</span>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { isLinkActive } from "./navigation-helper.js";

export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
    };
  },
  computed: {
    visibleLinks() {
      return this.menuItems
        .find((category) => category.label === this.activeCategory?.label)
        ?.links.filter((l) => l.accessible);
    },
  },
  created() {
    this.$bus.$on("activate-nav-category", this.onCategoryActivated);
  },
  destroyed() {
    this.$bus.$off("activate-nav-category", this.onCategoryActivated);
  },
  methods: {
    isLinkActive(link) {
      return isLinkActive(link);
    },
    onCategoryActivated(category) {
      this.activeCategory = category;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-top: var(--top-bar-height);

  &.desktop {
    min-width: 206px;
    max-width: 206px;
  }
}

.navbar-nav {
  width: 100%;
  color: var(--color-tint);
}

.font-size-title-small {
  font-size: 18px;
}

.category-title-wrapper {
  min-height: 60px;
}

.list-group {
  width: 100%;
  overflow-y: auto;
}

.width-limit {
  max-width: 290px;
}

.list-group-item {
  height: 48px;
  border-radius: 56px;
  border: none;
  padding: 1rem;
  color: var(--color-tint);

  &::before {
    display: none;
  }

  &.active {
    font-weight: bold;
    color: inherit;
    background-color: var(--color-ash);
  }

  &:focus-visible,
  &:hover {
    outline: none;
    background-color: var(--color-ash);
  }
}

::v-deep .dropdown-divider {
  margin: 0 !important;
}

.gap-2 {
  gap: 8px;
}

.lock-container {
  width: 24px;
  height: 24px;
  background-color: var(--color-accents-blush) !important;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.app-icon {
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
