<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="d-flex align-items-center">
    <span
      v-for="n in steps"
      :key="n"
      class="mr-3 pos__step"
      :class="{
        'pos__step--current': n === currentStep,
        'pos__step--previous': n < currentStep,
      }"
      @click="onClick(n)"
    />
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: null,
      required: true,
    },
    currentStep: {
      type: Number,
      default: null,
      required: true,
    },
  },
  methods: {
    onClick(step) {
      if (step >= this.currentStep) {
        return;
      }

      this.$bus.$emit("presales-onboarding-steps:change-tab", step);
    },
  },
};
</script>

<style lang="scss" scoped>
.pos {
  &__step {
    height: 17px;
    width: 17px;

    border: 3px solid #f26b4f;
    border-radius: 50%;

    position: relative;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      border-bottom: 1px solid #f26b4f;
      width: 16px;
      top: 5px;
      right: -19px;
    }

    &:last-child:after {
      display: none;
    }

    &--current {
      border: 3px solid #f26b4f;
    }

    &--previous {
      background: #f26b4f;
      border: 3px solid #f26b4f;

      cursor: pointer;
    }
  }
}
</style>
