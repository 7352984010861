<template>
  <div class="mb-6">
    <h1>
      AI Assistant
      <b-button v-if="conversation" variant="primary" @click="handleNewConversationClick">New chat</b-button>
    </h1>

    <div v-if="conversation" class="mb-5">
      <div v-for="message in conversation.messages" :key="message.id" :id="`message-${message.id}`" class="message">
        <div class="disciple-card mb-2">
          <h4><b-badge variant="primary">You</b-badge></h4>
          <div class="content">{{ message.user_content }}</div>
        </div>
        <div class="disciple-card mb-2">
          <div class="d-flex">
            <h4><b-badge variant="info">Assistant</b-badge></h4>
            <div v-if="message.feedback_rating" class="ml-auto">
              <ion-icon v-if="message.feedback_rating >= 3" name="thumbs-up" class="feedback-icon hydrated" />
              <ion-icon v-else name="thumbs-down" class="feedback-icon ml-2 hydrated" />
            </div>
            <div v-else class="ml-auto">
              <ion-icon
                name="thumbs-up-outline"
                class="feedback-icon feedback-icon--clickable hydrated"
                @click="handleMessageThumbsUpClick(message.id)"
              />
              <ion-icon
                name="thumbs-down-outline"
                class="feedback-icon feedback-icon--clickable ml-2 hydrated"
                @click="handleMessageThumbsDownClick(message.id)"
              />
            </div>
          </div>
          <div class="content">{{ message.assistant_content }}</div>
        </div>
      </div>
    </div>

    <div class="position-relative">
      <b-form-textarea
        v-model="message"
        v-autosize-textarea
        :disabled="isBusy"
        class="message-textarea"
        placeholder="Press ENTER to send the message"
        @keydown.enter.exact.prevent="handleSendClick"
      />

      <disciple-spinner v-if="isBusy" class="textarea-overlay" />
      <ion-icon v-else name="send" size="large" class="textarea-overlay send-icon" @click="handleSendClick" />
    </div>

    <b-modal
      id="message-feedback-modal"
      title="Provide feedback"
      centered
      ok-title="Submit"
      ok-only
      @ok="handleFeedbackOk"
      @close="handleFeedbackClose"
    >
      <b-form>
        <b-form-group label="Comment" label-for="feedback-comment">
          <b-form-textarea v-model="feedback.comment" v-autosize-textarea id="feedback-comment" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import DiscipleSpinner from "app_manager/components/disciple-spinner";

export default {
  components: {
    DiscipleSpinner,
  },
  data: function () {
    return {
      isBusy: true,

      conversation: null,
      message: "",

      feedback: {
        messageId: null,
        rating: null,
        comment: "",
      },

      clientId: null,
    };
  },
  beforeMount() {
    this.clientId = this.$el.dataset.clientId;
  },
  mounted() {
    this.loadConversation().then(() => {
      this.isBusy = false;
    });
  },
  methods: {
    async loadConversation() {
      const response = await $.get(`/api/clients/${this.clientId}/chat_conversations/last`);
      this.conversation = response.conversation;
    },
    handleNewConversationClick() {
      this.conversation = null;
      this.message = "";
    },
    handleMessageThumbsUpClick(messageId) {
      this.openFeedbackModal(messageId);
      this.saveFeedback(messageId, { rating: 5 });
    },
    handleMessageThumbsDownClick(messageId) {
      this.openFeedbackModal(messageId);
      this.saveFeedback(messageId, { rating: 1 });
    },
    openFeedbackModal(messageId) {
      this.feedback.messageId = messageId;
      this.$bvModal.show("message-feedback-modal");
    },
    async saveFeedback(messageId, attrs) {
      try {
        const response = await $.ajax({
          method: "POST",
          url: `/api/chat_messages/${messageId}/feedback`,
          data: {
            feedback: attrs,
          },
        });
        const messageIndex = this.conversation.messages.findIndex((m) => m.id === messageId);
        this.conversation.messages.splice(
          messageIndex,
          1,
          Object.assign({}, this.conversation.messages[messageIndex], response.message),
        );
      } catch (e) {
        this.$bvToast.toast("The request has failed.", {
          variant: "danger",
          title: "Error",
        });
      }
    },
    handleFeedbackOk() {
      const messageId = this.feedback.messageId;
      const comment = this.feedback.comment;
      this.resetFeedback();

      this.saveFeedback(messageId, { comment });
    },
    handleFeedbackClose() {
      this.resetFeedback();
    },
    resetFeedback() {
      this.feedback.messageId = null;
      this.feedback.rating = null;
      this.feedback.comment = "";
    },
    async handleSendClick() {
      if (this.isBusy) {
        return;
      }

      if (/^\s*$/.test(this.message)) {
        return;
      }

      this.isBusy = true;
      try {
        const response = await $.ajax({
          method: "POST",
          url: this.conversation
            ? `/api/chat_conversations/${this.conversation.id}/messages`
            : `/api/clients/${this.clientId}/chat_conversations`,
          data: {
            message: {
              content: this.message,
            },
          },
        });
        this.conversation = response.conversation;
        this.message = "";
      } catch (e) {
        this.$bvToast.toast(e.responseJSON.errors.base.join(". "), {
          variant: "danger",
          title: "Error",
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea-overlay {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  overflow-y: hidden;
}

.send-icon {
  cursor: pointer;
}

.content {
  white-space: pre-wrap;
}

.feedback-icon {
  font-size: 1.5rem;

  &--clickable {
    cursor: pointer;
  }
}
</style>
