<template>
  <b-navbar
    :class="{ 'desktop-with-navigation': $mq === 'lg' && hasSideNavigation }"
    class="py-0"
    app
    color="var(--color-white)"
    flat
    :height="64"
    fixed="top"
  >
    <a
      v-if="$mq === 'lg' && communityUrl"
      class="d-flex align-items-center color-tint text-decoration-none ml-3"
      :href="rootUrl"
    >
      <img class="mr-2" :src="require('./assets/disciple-logo.svg')" height="36" width="36" />
      <h1 class="font-size-title font-weight-bold mb-0">Disciple Console</h1>
    </a>
    <b-btn
      v-else-if="hasSideNavigation"
      class="my-0 p-0 nav-category-toggle"
      @click="$bus.$emit('toggle-mobile-nav-displayed')"
    >
      <div class="img-wrapper d-flex align-items-center justify-content-center">
        <img src="./assets/three-lines.svg" width="20" height="20" />
      </div>
    </b-btn>

    <b-navbar-nav class="mr-4" :class="$mq === 'lg' ? 'ml-auto' : 'flex-grow-1'">
      <a v-if="this.upgradeSubscriptionUrl" :href="this.upgradeSubscriptionUrl" class="btn upgrade-button">
        <div class="d-flex align-items-center">
          <svg
            class="app-icon"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 120 120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#uicons-gipplxrt97)">
              <path
                d="M65.7274 46.1279C64.7837 47.2367 61.3648 49.2631 60.1848 47.4415C59.4293 46.2751 59.443 44.4376 60.0299 42.6274C60.7661 40.3416 63.6886 39.966 65.0467 40.9743C66.7166 42.2142 67.2867 44.2974 65.7274 46.1279Z"
                fill="white"
              ></path>
              <path
                d="M46.558 50.9589C48.9209 52.2156 50.5398 54.1573 50.037 55.975C49.5343 57.7927 47.1157 58.1882 44.6189 57.9446C42.2284 57.7161 40.8588 55.2111 41.3935 53.2878C41.9282 51.3643 44.436 49.829 46.558 50.9589Z"
                fill="white"
              ></path>
              <path
                d="M44.3581 60.6167C47.6365 59.5123 49.3835 60.6468 49.8729 61.9868C50.4182 63.4798 49.4306 65.8237 46.9556 67.3074C45.9488 67.9136 45.2204 68.073 44.2874 67.9201C40.9999 67.3815 40.1579 62.9564 43.0089 61.2333C43.4045 60.9942 43.8547 60.7863 44.3581 60.6167Z"
                fill="white"
              ></path>
              <path
                d="M58.2036 76.1288C58.369 74.3603 59.5452 72.2398 61.2089 71.803C63.1566 71.2918 64.3117 73.2534 64.6558 74.8775C65.0216 76.6047 64.5021 78.7646 62.6865 79.4941C60.7157 80.286 58.3812 78.9329 58.1982 76.8828C58.1768 76.6435 58.1792 76.39 58.2036 76.1288Z"
                fill="white"
              ></path>
              <path
                d="M67.064 74.8936C66.3993 73.0158 66.5928 71.5358 67.7828 70.7912C68.9727 70.0467 70.3934 70.6463 71.641 72.0355C72.8378 73.3631 72.3296 75.4264 71.069 76.2128C69.8083 76.9994 67.6601 76.5807 67.064 74.8936Z"
                fill="white"
              ></path>
              <path
                d="M72.8384 70.5778C71.6565 69.5671 70.0578 67.7942 70.6357 66.0738C71.3703 63.8867 73.9319 64.4269 75.6682 64.9935C77.7692 65.6747 78.198 67.8944 77.3349 69.5149C76.4719 71.1356 74.5189 72.0149 72.8384 70.5778Z"
                fill="white"
              ></path>
              <path
                d="M75.4446 53.2678C73.8743 53.9202 72.4743 53.8965 71.7852 52.9162C71.0962 51.936 71.672 50.6241 72.7992 49.4972C73.876 48.4164 75.6579 48.7552 76.3859 49.7937C77.114 50.8323 76.8554 52.6825 75.4446 53.2678Z"
                fill="white"
              ></path>
              <path
                d="M55.3744 56.1988C57.2239 56.5387 58.0256 56.9596 58.76 58.1209C59.2638 58.9178 59.2181 59.9767 58.5976 60.6865C57.8502 61.5414 57.0116 62.273 55.6057 62.5723C53.5404 63.0163 51.7258 61.2798 51.6628 59.5249C51.5998 57.7697 53.2946 55.8154 55.3744 56.1988Z"
                fill="white"
              ></path>
              <path
                d="M61.5934 67.4799C60.7349 65.1343 60.7011 63.009 62.1832 62.0595C63.6653 61.11 66.0034 61.8816 67.294 63.8352C68.5767 65.7769 68.4437 68.2824 66.6327 69.0805C64.9277 69.8318 62.3634 69.5873 61.5934 67.4799Z"
                fill="white"
              ></path>
              <path
                d="M67.7152 55.4511C65.9713 57.55 63.9558 58.8314 62.245 57.9529C60.3358 56.9724 60.2723 54.4697 60.9308 52.6912C61.8086 50.3204 63.947 48.9735 66.4594 49.8128C68.39 50.4578 69.2826 53.5665 67.7152 55.4511Z"
                fill="white"
              ></path>
              <path
                d="M57.4817 46.8566C58.4624 49.6292 58.4161 52.2217 56.5692 53.162C53.5591 54.6946 47.0285 49.0264 49.2207 45.3917C49.8441 44.3581 51.1051 43.2977 52.3041 43.1584C54.2501 42.9322 56.767 44.8326 57.4817 46.8566Z"
                fill="white"
              ></path>
              <path
                d="M51.2067 67.3418C52.5419 65.7792 55.5888 64.5156 57.1795 65.5503C58.5265 66.4265 58.4927 68.9879 57.932 70.9211C56.8763 74.5614 53.9625 77.2168 50.2957 75.2897C46.895 73.5024 49.5644 69.2617 51.2067 67.3418Z"
                fill="white"
              ></path>
              <path
                d="M71.6866 62.1141C70.9741 61.9352 70.317 61.7129 69.7865 61.4911C68.7713 61.0668 68.1445 60.3263 68.1445 59.1907C68.1445 57.7401 69.4514 56.9995 71.9848 56.1059C74.1404 55.3456 78.628 55.1451 78.628 59.2809C78.628 62.8504 74.5571 62.8348 71.6866 62.1141Z"
                fill="white"
              ></path>
              <path
                d="M60 32C44.536 32 32 44.536 32 60C32 75.464 44.536 88 60 88C75.464 88 88 75.464 88 60C88 44.536 75.464 32 60 32ZM74.0623 77.5573C71.1417 79.7728 68.3418 81.0426 64.8413 81.9503C61.569 82.7987 58.2834 81.9806 55.1419 81.1217C52.0413 80.274 48.9066 79.0173 46.4623 76.873C43.6607 74.4145 41.4685 72.3936 39.6679 69.1306C38.1976 66.4663 37.6472 63.4802 37.6084 60.4311C37.5679 57.2486 37.9079 53.7925 39.3122 50.854C40.6983 47.9535 43.5347 46.0528 45.8445 43.9387C48.2183 41.766 50.068 40.1085 53.2268 39.138C58.4217 37.5419 64.3399 36.3036 69.2612 39.5491C72.5186 41.6972 73.4815 42.1428 76.4195 44.7065C81.1045 48.7949 81.9981 51.8146 82.2037 59.3955C82.2878 62.4951 81.6393 66.309 80.7827 68.5639C79.4172 72.1585 76.8229 75.4632 74.0623 77.5573Z"
                fill="white"
              ></path>
              <path
                d="M72.5473 46.9494C72.0328 47.839 70.377 48.5152 69.4874 48.0007C68.5978 47.4862 68.8113 45.9758 69.3258 45.0862C69.8403 44.1966 70.9785 43.8925 71.8681 44.407C72.7577 44.9216 73.0618 46.0598 72.5473 46.9494Z"
                fill="white"
              ></path>
            </g>
            <defs>
              <clipPath id="uicons-gipplxrt97">
                <rect width="56" height="56" fill="white" transform="translate(32 32)"></rect>
              </clipPath>
            </defs>
          </svg>

          <div>Upgrade your plan</div>
        </div>
      </a>
      <a
        class="mx-3 d-flex align-items-center justify-content-end flex-shrink color-tint text-decoration-none"
        :href="communityUrl"
      >
        <img
          v-if="appLogoUrl"
          class="app-logo mr-2"
          :src="appLogoUrl"
          :height="$mq === 'lg' ? 32 : 40"
          :width="$mq === 'lg' ? 32 : 40"
        />
        <img
          v-else-if="!communityUrl"
          class="app-logo mr-2"
          :src="require('./assets/disciple-logo.svg')"
          :height="$mq === 'lg' ? 32 : 40"
          :width="$mq === 'lg' ? 32 : 40"
        />

        <div class="app-name font-size-big font-weight-bold">{{ appName }}</div>
      </a>

      <host-menu v-if="hostMenuLinks" :host-menu-links="hostMenuLinks" />
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import HostMenu from "app_manager/components/navigation/host-menu";

export default {
  components: {
    HostMenu,
  },
  data() {
    return {
      appName: null,
      appLogoUrl: null,
      communityUrl: null,
      hostMenuLinks: null,
      upgradeSubscriptionUrl: null,
      rootUrl: null,
    };
  },
  beforeMount() {
    this.appName = this.$el.attributes["app-name"]?.value;
    this.appLogoUrl = this.$el.attributes["app-logo-url"]?.value;
    this.communityUrl = this.$el.attributes["community-url"]?.value;
    this.rootUrl = this.$el.attributes["root-url"]?.value;
    this.upgradeSubscriptionUrl = this.$el.attributes["upgrade-subscription-url"]?.value;
    const navigationData = JSON.parse(this.$el.attributes["navigation-data-string"]?.value);
    this.hostMenuLinks = navigationData.host_menu_items;
    this.hasSideNavigation = navigationData.menu_items?.length > 0;
  },
};
</script>

<style lang="scss" scoped>
.font-size-title {
  font-size: 1.25rem;
}

.navbar {
  height: 64px;
  left: 0 !important;
  margin-left: -1px;
  border-bottom: var(--color-light-gray) solid 1px !important;
  background-color: var(--color-white);
  z-index: 1039;

  .app-logo {
    border-radius: 6px;
  }

  &.desktop-with-navigation {
    left: 88px !important;
  }
}

.navbar-nav {
  justify-content: space-between;
  align-items: center;
}

.nav-category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 65px !important;
  height: 64px !important;
  border: solid var(--color-light-gray);
  border-width: 0 1px 1px 0;
  border-radius: 0;
  background-color: var(--color-ash) !important;

  &:active {
    box-shadow: none !important;
  }
}

.desktop {
  .nav-category-toggle {
    &:hover {
      .img-wrapper {
        box-shadow:
          0px 0.5px 5px rgba(0, 0, 0, 0.08),
          0px 2px 2px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.img-wrapper {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
}

.upgrade-button {
  display: inline-grid;
  background-color: var(--color-rose) !important;
  margin-right: 4px;
  height: 40px;
  min-width: 40px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  padding: 0 16px;
  opacity: 0.85;
  letter-spacing: normal;
  .app-icon {
    margin-left: -12px !important;
    height: 32px;
    width: 32px;
    min-width: 32px;
    vertical-align: middle;
  }
}
</style>
