var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-b-modal',{attrs:{"id":_vm.modalId,"title":_vm.modalTitle,"no-close-on-backdrop":"","ok-title":"Save"},on:{"show":_vm.resetCurrentColors,"ok":_vm.saveChanges}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col example-column"},[_c('div',{staticClass:"background-rect",style:({
          backgroundColor: _vm.currentColors.background,
          color: _vm.currentColors.text,
        })},[_vm._v("\n        Text\n      ")]),_vm._v(" "),_c('div',{staticClass:"button-rect",style:({
          backgroundColor: _vm.currentColors.button,
          color: _vm.currentColors.background,
        })},[_vm._v("\n        BUTTON\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col picker-column"},[_c('chrome-picker',{attrs:{"disable-alpha":true},model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }