<template>
  <div class="community-preview__frame">
    <div class="community-preview__content" :style="{ 'background-color': masterColors.wall_background }">
      <url-bar v-bind="$props" />
      <top-header v-bind="$props" />
      <my-groups v-bind="$props" />
      <activity-feed v-bind="$props" />
      <bottom-nav v-bind="$props" />
      <plus-button v-bind="$props" />
    </div>
  </div>
</template>

<script>
import UrlBar from "./community-preview/url-bar";
import TopHeader from "./community-preview/top-header";
import MyGroups from "./community-preview/my-groups";
import ActivityFeed from "./community-preview/activity-feed";
import BottomNav from "./community-preview/bottom-nav";
import PlusButton from "./community-preview/plus-button";

export default {
  components: {
    UrlBar,
    TopHeader,
    MyGroups,
    ActivityFeed,
    BottomNav,
    PlusButton,
  },
  props: {
    communityUrl: {
      type: String,
      default: "",
    },
    logoUrl: {
      type: String,
      default: null,
    },
    masterColors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.community-preview {
  &__frame {
    width: 321px;
    height: 644px;

    padding: 15px 16px;

    background-image: url("./community-preview/assets/phoneframe.svg");

    position: sticky;
    top: calc(64px + 1.5rem);
  }

  &__content {
    position: relative;
    height: 100%;
    overflow: hidden;

    border-radius: 40px;
  }
}
</style>
