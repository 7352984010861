<template>
  <custom-b-modal
    :id="modalId"
    :title="modalTitle"
    no-close-on-backdrop
    ok-title="Save"
    @show="resetCurrentColors"
    @ok="saveChanges"
  >
    <div class="row">
      <div class="col example-column">
        <div
          :style="{
            backgroundColor: currentColors.background,
            color: currentColors.text,
          }"
          class="background-rect"
        >
          Text
        </div>
        <div
          :style="{
            backgroundColor: currentColors.button,
            color: currentColors.background,
          }"
          class="button-rect"
        >
          BUTTON
        </div>
      </div>
      <div class="col picker-column">
        <chrome-picker v-model="selectedColor" :disable-alpha="true" />
      </div>
    </div>
  </custom-b-modal>
</template>

<script>
import { Chrome as ChromePicker } from "vue-color";

export default {
  components: {
    ChromePicker,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    modalId: {
      type: String,
      required: true,
    },
    colorName: {
      type: String,
      required: true,
    },
    backgroundHexColor: {
      type: String,
      required: true,
    },
    textHexColor: {
      type: String,
      required: true,
    },
    buttonHexColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentColors: {
        background: this.backgroundHexColor,
        text: this.textHexColor,
        button: this.buttonHexColor,
      },
    };
  },
  computed: {
    selectedColor: {
      get() {
        return this.currentColors[this.colorName];
      },
      set(newVal) {
        this.currentColors[this.colorName] = newVal.hex;
      },
    },
    modalTitle() {
      return `Update ${this.colorName} colour`;
    },
  },
  watch: {
    backgroundHexColor(newVal) {
      this.currentColors.background = newVal;
    },
    textHexColor(newVal) {
      this.currentColors.text = newVal;
    },
    buttonHexColor(newVal) {
      this.currentColors.button = newVal;
    },
  },
  methods: {
    saveChanges() {
      this.$emit("input", this.currentColors[this.colorName]);
    },
    resetCurrentColors() {
      this.currentColors.background = this.backgroundHexColor;
      this.currentColors.text = this.textHexColor;
      this.currentColors.button = this.buttonHexColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.background-rect {
  align-items: center;
  border-color: rgba(0, 0, 0, 0.8);
  border-style: solid;
  border-width: 1px 1px 0;
  display: flex;
  flex-grow: 9;
  font-size: 1.5rem;
  justify-content: center;
}

.button-rect {
  align-items: center;
  border-color: rgba(0, 0, 0, 0.8);
  border-style: solid;
  border-width: 0 1px 1px;
  display: flex;
  flex-grow: 1;
  font-size: 1.1rem;
  justify-content: center;
}

.example-column {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.picker-column {
  margin: 2rem 0.5rem;
}
</style>
