<template>
  <form :method="method" :action="action" @keypress="handleKeypress($event)">
    <input v-if="authenticityToken" :value="authenticityToken" type="hidden" name="authenticity_token" />
    <slot />
  </form>
</template>

<script>
export default {
  props: {
    method: {
      type: String,
      default: "POST",
      validator(value) {
        return value === "GET" || value === "POST";
      },
    },
    action: {
      type: String,
      required: true,
    },
    disableSubmitOnEnter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authenticityToken: $("meta[name='csrf-token']").attr("content"),
    };
  },
  methods: {
    handleKeypress(event) {
      if (this.disableSubmitOnEnter && event.which === 13) {
        event.preventDefault();
      }
    },
  },
};
</script>
