<template>
  <div :class="{ 'side-navigation-desktop': $mq === 'lg' }">
    <template v-if="$mq === 'lg'">
      <navigation-categories-desktop :menu-items="menuItems" />
      <navigation-links-drawer :menu-items="menuItems" />
    </template>
    <side-navigation-mobile v-else :menu-items="menuItems" />
  </div>
</template>

<script>
import SideNavigationMobile from "app_manager/components/navigation/side-navigation-mobile";
import NavigationCategoriesDesktop from "app_manager/components/navigation/navigation-categories-desktop";
import NavigationLinksDrawer from "app_manager/components/navigation/navigation-links-drawer";

export default {
  components: {
    SideNavigationMobile,
    NavigationCategoriesDesktop,
    NavigationLinksDrawer,
  },
  data() {
    return {
      menuItems: null,
    };
  },
  beforeMount() {
    this.menuItems = JSON.parse(this.$el.attributes["navigation-data-string"].value).menu_items;
  },
};
</script>

<style lang="scss" scoped>
// Fixes Firefox glitch (right hand side border ghosting) which happens during sidebar animation
// Can't apply to mobile because then sidebar won't overlap the page
.side-navigation-desktop {
  ::v-deep .b-sidebar-outer {
    position: unset !important;
  }
}
</style>
