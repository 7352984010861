<template lang="slm">
  b-form-group class="disciple-switch-form-group"
    label> v-b-tooltip="tooltip" :for="inputId" :class="{'sr-only': hideLabel}" {{label}}
    input[
      :name="inputName"
      type="hidden"
      value="0"
    ]
    label.disciple-switch style="float:right;" :class="classColour"
      input.disciple-switch__input[
        :id="inputId"
        :name="inputName"
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        @change="updateValue($event.target.checked)"
      ]
      span.disciple-switch__label
      span.disciple-switch__handle
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    colour: {
      type: String,
      default: null,
    },
  },
  computed: {
    inputId() {
      return "disciple-switch-" + this._uid;
    },
    classColour() {
      return this.colour ? `disciple-switch--${this.colour}` : "";
    },
  },
  methods: {
    updateValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>
